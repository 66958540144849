import React from "react";
import { useState, useEffect } from "react";



function Preloaders() {

  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 1000)


  }, [])

  return (
    <div>{

      loading ?

        <div id="preloader">
          <div id="loading-center">
            <div id="object"></div>
          </div>
        </div>
        :
        <div>
        </div>

    }
    </div>
  );
};

export default Preloaders;
