import React from "react";
import { Link } from "react-router-dom"
import { useEffect, useState } from "react";
import Preloaders from "../components/Preloaders";



function ML() {

    const [isMenuOpen, setMenuOpen] = useState(false);
    const [isSubMenuOpen, setSubMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!isMenuOpen);
        // Close submenu when main menu is toggled
        setSubMenuOpen(false);
    };

    const toggleSubMenu = () => {
        setSubMenuOpen(!isSubMenuOpen);
    };
    const closeMenu = () => {
        setMenuOpen(false);
    };

    /*landing page opens at the top of the page*/
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    /* Title page and meta description content has been load from here for their rspective page */
    useEffect(() => {
        document.title = "Data-Driven Innovation: Unlock Growth with Ero Edge's ML Expertise";
        const metaDescriptionTag = document.querySelector('meta[name="description"]');
        if (metaDescriptionTag) {
            metaDescriptionTag.setAttribute('content', "Predict customer behavior, personalize experiences, and drive growth with cutting-edge machine learning solutions from Ero Edge. Contact us to unleash your data's potential!");
        }
    }, []);

    return (

        <div className="restrict_content">
            {/* <!--====== Start Preloader ======--> */}
            <Preloaders />
            {/* <!--====== End Preloader ======-->  */}

            {/* <!--====== Start Header ======--> */}
            <header className="template-header absolute-header navbar-left sticky-header">
                <div className="container">
                    <div className="header-inner">
                        <div className="header-left">
                            <div className="site-logo">
                                <Link to="/Home">
                                    <img src="assets/img/logo/ero_edge_logo.webp" alt="ero_edge_logo" className="custom_logo_design" />
                                </Link>
                            </div>
                            <nav className="nav-menu d-none d-xl-block">
                                <ul className="primary-menu">
                                    <li>
                                        <Link to="/Home">Home</Link>
                                    </li>
                                    <li>
                                        <Link to="#" className="active">Services</Link>
                                        <ul className="submenu">
                                            <li><Link to="/Software">Software Development</Link></li>
                                            <li><Link to="/Web">Web Application Development</Link></li>
                                            <li><Link to="/Mobile">Mobile Application Development</Link></li>
                                            <li><Link to="/AI">Artificial Intelligence (AI)</Link></li>
                                            <li><Link to="/ML">Machine Learning (ML)</Link></li>
                                            <li><Link to="/Digital">Digital Marketing</Link></li>
                                        </ul>
                                    </li>
                                    <li>
                                        <Link to="/Careers">Careers</Link>
                                    </li>
                                    <li>
                                        <Link to="/About">About Us</Link>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                        <div className="header-right">
                            <ul className="header-extra">
                                <li className="header-btns d-none d-sm-block">
                                    <Link to="/Contact" className="template-btn">
                                        Contact Us
                                        <i className="far fa-long-arrow-right"></i>
                                    </Link>
                                </li>

                                <li className="d-xl-none">
                                    {/* Mobile-menu */}
                                    <div className="#" onClick={toggleMenu}>
                                        <div className="navbar-toggler hamburger_align">
                                            <span className="hamburger_span_align"></span>
                                            <span></span>
                                            <span></span>
                                        </div>
                                    </div>
                                    {isMenuOpen && (
                                        <div className=" mobile-slide-panel-1 slide-panel-1 ">
                                            <div className="panel-inner">
                                                <div className="custom-close-button" onClick={closeMenu}>
                                                    <i className="fal fa-times"></i>
                                                </div>
                                                <div className="custom-panel-logo">
                                                    <img src="assets/img/logo/ero_edge_logo.webp" alt="ero_edge_logo" className="custom_logo_menu_design" />
                                                </div>
                                                <div>
                                                    <nav className="mobile-menu">
                                                        <ul>
                                                            <li>
                                                                <Link to="/Home">Home</Link>
                                                            </li>
                                                            <li>
                                                                <Link to="#" onClick={toggleSubMenu}>Services</Link>
                                                                {isSubMenuOpen && (
                                                                    <ul className="custom-submenu">
                                                                        <li><Link to="/Software">Software Development</Link></li>
                                                                        <li><Link to="/Web">Web Application Development</Link></li>
                                                                        <li><Link to="/Mobile">Mobile Application Development</Link></li>
                                                                        <li><Link to="/AI">Artificial Intelligence(AI)</Link></li>
                                                                        <li><Link to="/ML" className="active">Machine Learning (ML)</Link></li>
                                                                        <li><Link to="/Digital">Digital Marketing</Link></li>
                                                                    </ul>
                                                                )}
                                                            </li>
                                                            <li>
                                                                <Link to="/Careers">Careers</Link>
                                                            </li>
                                                            <li>
                                                                <Link to="/About">About us</Link>
                                                            </li>
                                                        </ul>
                                                        <div>
                                                            <Link to="/Contact" className="template-btn hamburger_contact_btn_align">
                                                                Contact Us
                                                                <i className="far fa-long-arrow-right"></i>
                                                            </Link>
                                                        </div>
                                                    </nav>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </header>
            {/* <!--====== End Header ======-->
        
            <!--====== Page Title Area Start ======--> */}
            <section className="page-title-area">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <h2 className="page-title">Machine Learning</h2>
                        </div>
                        <div className="col-md-6">
                            <ul className="breadcrumb-nav">
                                <li><Link to="/Home">Home</Link></li>
                                <li>ML</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!--====== Page Title Area End ======-->

            <!--====== Feature Section Start ======--> */}
            <section className="feature-section section-gap" style={{ marginBottom: "-20px" }} >
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-lg-6 col-md-10">
                            <div className="feature-img text-lg-left text-center content-mb-md-50"><img src="assets/img/service_images/ai_second.svg" alt="artificial_intelligence_second" className="animate-float-bob-y" style={{ marginTop: '-30px', marginBottom: '-40px' }} />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-10">
                            <div className="feature-text-block content-l-spacing">
                                <div className="section-heading mb-30">
                                    <h2 className="title">We’re Very Experience In Machine Learning</h2>
                                    <span className="tagline">Welcome to Ero Edge Technologies, where our Machine Learning Services pave the way for intelligent and data-driven solutions. Harness the power of ML to unlock new possibilities and propel your business into the era of predictive analytics.</span>
                                </div>
                                <div className="feature-lists">
                                    <div className="simple-icon-box icon-left mb-30">
                                        <div className="icon">
                                            <img src="assets/img/icon/approach.svg" alt="approach" className="custom_img_icon_1" />
                                        </div>
                                        <div className="content">
                                            <h4 className="title">Our Approach</h4>
                                            <p>At Ero Edge, our Machine Learning approach is rooted in innovation and practical application. We blend advanced algorithms with domain expertise to create ML solutions that address complex challenges and deliver tangible results.</p>
                                        </div>
                                    </div>
                                    <div className="simple-icon-box icon-left mb-30">
                                        <div className="icon">
                                            <img src="assets/img/icon/technology.svg" alt="technology" className="custom_img_icon_1" />
                                        </div>
                                        <div className="content">
                                            <h4 className="title">Technological Expertise</h4>
                                            <p>Our ML specialists are well-versed in a variety of machine learning frameworks and technologies, ensuring that our solutions are not only cutting-edge but also tailored to the unique needs of your business.</p>
                                        </div>
                                    </div>
                                    <div className="simple-icon-box icon-left mb-30">
                                        <div className="icon">
                                            <img src="assets/img/icon/industrial.svg" alt="industrial" className="custom_img_icon_1" />
                                        </div>
                                        <div className="content">
                                            <h4 className="title">Industries We Serve</h4>
                                            <p>From healthcare and finance to e-commerce and beyond, our ML solutions have made a significant impact. We understand the specific challenges each industry faces and customize our ML services accordingly.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!--====== Feature Section End ======--> */}


            {/* <!--====== Service Section Start ======--> */}
            <section className="service-section section-gap bg-cover-center" style={{ backgroundImage: "url(assets/img/hero/hero-bg-two.jpg)" }}>
                <div className="container">
                    <div className="section-heading text-center mb-30">
                        <h2 className="title">Services Offered</h2>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-4 col-sm-6 wow fadeInUp custom_card_align">
                            <div className="iconic-box icon-left mt-30">
                                <div className="icon">
                                    <img src="assets/img/icon/industrial.svg" alt="industrial" />
                                </div>
                                <div className="content">
                                    <h5 className="title">Predictive Analytics</h5>
                                    <p>Leverage historical data to make informed predictions and strategic decisions.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 wow fadeInUp custom_card_align">
                            <div className="iconic-box icon-left mt-30 ml_card_bg_color">
                                <div className="icon">
                                    <img src="assets/img/icon/announcement.svg" alt="announcement" />
                                </div>
                                <div className="content">
                                    <h4 className="title">Recommendation Systems</h4>
                                    <p>Enhance user experiences with personalized recommendations powered by ML.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 wow fadeInUp custom_card_align">
                            <div className="iconic-box icon-left mt-30">
                                <div className="icon">
                                    <img src="assets/img/icon/pattern.svg" alt="pattern" />
                                </div>
                                <div className="content">
                                    <h4 className="title">Pattern Recognition</h4>
                                    <p>Uncover valuable insights through the identification of patterns in large datasets.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br></br>
                <div className="container">
                    <div className="section-heading text-center mb-30">
                        <h2 className="title">Key Features</h2>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-4 col-sm-6 wow fadeInUp custom_card_align">
                            <div className="iconic-box icon-left mt-30 ml_card_bg_color">
                                <div className="icon">
                                    <img src="assets/img/icon/data_driven.svg" alt="data_driven" />
                                </div>
                                <div className="content">
                                    <h5 className="title">Data-driven Decision Making</h5>
                                    <p>Empower your business with actionable insights derived from ML analysis.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 wow fadeInUp custom_card_align">
                            <div className="iconic-box icon-left mt-30">
                                <div className="icon">
                                    <img src="assets/img/icon/customize.svg" alt="customize" />
                                </div>
                                <div className="content">
                                    <h4 className="title">Scalability</h4>
                                    <p>ML solutions designed to grow with your data and adapt to changing business requirements.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 wow fadeInUp custom_card_align">
                            <div className="iconic-box icon-left mt-30 ml_card_bg_color">
                                <div className="icon">
                                    <img src="assets/img/icon/learning.svg" alt="learning" />
                                </div>
                                <div className="content">
                                    <h4 className="title">Continuous Learning</h4>
                                    <p>Implement systems that evolve and improve over time through ongoing learning processes.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!--====== Service Section End ======--> */}

            {/* <!--====== Service Section-2 Start ======--> */}
            <section className="service-section section-gap" style={{ marginBottom: "-40px" }} >
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-10 col-md-8">
                            <div className="section-heading mb-20 text-center">
                                <h2 className="title">Why Choose Ero Edge for ML Services</h2>
                            </div>
                            <ul className="circle-list mb-40 mt-30 wow fadeInUp">
                                <li>Innovative Solutions</li>
                                <p>Stay ahead in a data-driven world with our innovative ML services.</p>
                                <br></br>
                                <li>Proven Results</li>
                                <p>Explore our successful ML projects and see the transformative impact on businesses.</p>
                                <br></br>
                                <li>Collaborative Partnership</li>
                                <p>We work closely with clients to ensure our ML solutions align with their strategic goals.</p>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!--====== Service  Section-2 End ======--> */}

            {/* <!--====== Call To Action Start ======--> */}
            <section className="call-to-action bg-color-secondary section-animate-icons">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-lg-5 order-lg-last">
                            <div className="cta-illustrations text-center text-lg-right content-mb-md-50">
                                <img src="assets/img/illustrations/cta-illustrations.png" alt="cta-illustrations" />
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-10">
                            <div className="cta-content">
                                <h2 className="title">Ready to Harness the Power of Machine Learning ?</h2>
                                <p className="subtitle">Contact us today to discuss your ML requirements and discover how our expertise can bring predictive intelligence and strategic insights to your business.</p>
                                <Link to="/About" className="template-btn">Get in Touch ! <i className="far fa-long-arrow-right"></i></Link>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <!-- Animate Icons --> */}
                <div className="animate-icons">
                    <img src="assets/img/shape/dots-circle.png" alt="Icon" className="dots-circle" />
                </div>
                <div className="cta-shape">
                    <img src="assets/img/shape/cta-shape.png" alt="Shape" />
                </div>
            </section>
            {/* <!--====== Call To Action End ======-->
        
            <!--====== Template Footer Start ======--> */}
            <footer className="template-footer">
                <div className="container">
                    <div className="footer-widgets-area">
                        <div className="row">
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <div className="widget contact-widget">
                                    <h4 className="widget-title">Contact Us</h4>
                                    <div className="contact-content">
                                        <div className="icon">
                                            <p>
                                                <i className="fal fa-map-marker-alt">
                                                </i> 483, 3rdFloor, Block.No:18<br /> Laakini Towers, Vasuki Street,<br />Erode.
                                            </p>
                                            <p>
                                                <Link to="mailto:support@eroedgetechnologies.com">
                                                    <i className="fal fa-envelope-open">
                                                    </i> support@eroedge<br />technologies.com
                                                </Link>
                                            </p>
                                            <p>
                                                <i className="fal fa-phone">
                                                </i> +91 9698143213
                                            </p>

                                        </div>
                                        <img src="assets/img/logo/ero_edge_logo.webp" alt="ero_edge_logo" className="custom_logo_footer_design" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="widget nav-widget pl-lg-4">
                                    <h4 className="widget-title">Quick Link</h4>
                                    <ul>
                                        <li><Link to="/Software">Software Development</Link></li>
                                        <li><Link to="/Web">Web Application Development</Link></li>
                                        <li><Link to="/Mobile">Mobile Application Development</Link></li>
                                        <li><Link to="/AI">Artificial Intelligence(AI)</Link></li>
                                        <li><Link to="/Digital">Digital Marketing</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-6 col-sm-6">
                                <div className="widget nav-widget">
                                    <h4 className="widget-title">Support</h4>
                                    <ul>
                                        <li><Link to="/About">About Us</Link></li>
                                        <li><Link to="/Privacy">Privacy & Policy</Link></li>
                                        <li><Link to="/Careers">Need a Career?</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="widget">
                                    <h4 className="widget-title">Follow</h4>
                                    <ul className="social-links">
                                        <li><Link to="https://www.linkedin.com/company/ero-edge-technologies-private-limited"><i className="fab fa-linkedin"></i></Link></li>
                                        <li><Link to="https://www.facebook.com/p/Ero-Edge-Technologies-Private-Limited-61556438934930/"><i className="fab fa-facebook-square"></i></Link></li>
                                        <li><Link to="https://www.instagram.com/ero_edge_technologies/"><i className="fab fa-instagram"></i></Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="copyright-box">
                        <p>@ 2024 Ero Edge Technologies Pvt Ltd</p>
                    </div>
                </div>
            </footer>
            {/* <!--====== Template Footer End ======--> */}

        </div>


    );
}

export default ML;