import React from "react";
import Slick from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';

function Testimonial() {

    const settings = {

        infinite: true,
        // dots: true,
        arrows: false,
        speed: 200,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,

    };
    return (

        <div>
            {/*  <!--====== Testimonial Section Start ======--> */}

            <section className="testimonial-section section-gap">
                <div className="container">
                    <div className="row align-items-center justify-content-lg-start justify-content-center">
                        <div className="col-lg-5">
                            <div className="section-thumb text-lg-left text-center content-mb-md-50">
                                <img src="assets/img/testimonial/testimonial.svg" alt="testimonial" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-10">
                            <div className="testimonial-area content-l-spacing">
                                <div className="section-heading mb-50">
                                    <h2 className="title">What’s Clients Say</h2>
                                    <span className="tagline">Code with Passion, Serve with Precision.</span>
                                </div>
                                <Slick {...settings}>
                                    <div className="testimonial-item">
                                        <div className="content">
                                            <p>
                                                "Working with Ero Edge has been a game-changer for our project. Their commitment to excellence and timely delivery is commendable. The team's expertise and dedication have significantly contributed to the success of our collaboration."
                                            </p>
                                        </div>
                                        <div className="author">
                                            <div className="author-photo client_heading_align">
                                                <img src="assets/img/testimonial/eternls.png" alt="eternl" className="client_img_align"/>
                                            </div>
                                            <div className="author-info">
                                                <h4 className="name">Dr. Veeraragavan</h4>
                                                <span className="title">Eternl Resilient Tech</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="testimonial-item">
                                        <div className="content">
                                            <p>
                                                "Ero Edge has consistently delivered outstanding results within the agreed-upon timelines. Their dedication to meeting deadlines without compromising on quality is truly commendable. Working with Ero Edge has been a smooth and rewarding experience for our team."
                                            </p>
                                        </div>
                                        <div className="author">
                                            <div className="author-photo client_heading_align">
                                                <img src="assets/img/testimonial/ero_impexs.png" alt="ero_impex" className="client_img_align"/>
                                            </div>
                                            <div className="author-info">
                                                <h4 className="name">Mr. Sankar Nagarajan</h4>
                                                <span className="title">Ero Impex</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="testimonial-item">
                                        <div className="content">
                                            <p>
                                                "Ero Edge has been instrumental in bringing our vision to life. The prompt completion of our project by the Ero Edge team has been crucial to its success. Their efficient communication and top-notch work make them a reliable partner in any endeavor."
                                            </p>
                                        </div>
                                        <div className="author">
                                            <div className="author-photo" style={{ marginRight: '-10px' }}>
                                                <img src="assets/img/testimonial/profiles.jpg" alt="profile" className="client_img_align"/>
                                            </div>
                                            <div className="author-info">
                                                <h4 className="name">Mr. SanthoseKumar</h4>
                                                <span className="title">CRERA</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="testimonial-item">
                                        <div className="content">
                                            <p>
                                                "Ero Edge and the team have exceeded our expectations in every aspect. Their professionalism and attention to detail shine through in their work. We are truly grateful for the on-time delivery and the exceptional quality of the results."
                                            </p>
                                        </div>
                                        <div className="author">
                                            <div className="author-photo client_heading_align">
                                                <img src="assets/img/testimonial/profiles.jpg" alt="profile" className="client_img_align"/>
                                            </div>
                                            <div className="author-info">
                                                <h4 className="name">Mr. Sengotuvel </h4>
                                                <span className="title">JanaVirucham</span>
                                            </div>
                                        </div>

                                    </div>
                                </Slick>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <!--====== Testimonial Section End ======--> */}
        </div>
    );

};

export default Testimonial;