import React from "react";
import { Link } from "react-router-dom"
import { useState, useEffect } from "react";
import Preloaders from "../components/Preloaders";



function AI() {


    const [isMenuOpen, setMenuOpen] = useState(false);
    const [isSubMenuOpen, setSubMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!isMenuOpen);
        // Close submenu when main menu is toggled
        setSubMenuOpen(false);
    };

    const toggleSubMenu = () => {
        setSubMenuOpen(!isSubMenuOpen);
    };
    const closeMenu = () => {
        setMenuOpen(false);
    };

    /*landing page opens at the top of the page*/
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    /* Title page and meta description content has been load from here for their rspective page */
    useEffect(() => {
        document.title = "Future-Proofing Your Business: Embrace AI with Ero Edge Technologies";
        const metaDescriptionTag = document.querySelector('meta[name="description"]');
        if (metaDescriptionTag) {
            metaDescriptionTag.setAttribute('content', 'Enhance your business decision-making and unlock new potential with the power of AI. Ero Edge Technologies creates innovative AI solutions for various industries.');
        }
    }, []);

    return (


        <div className="restrict_content">
            {/* <!--====== Start Preloader ======--> */}
            <Preloaders />
            {/* <!--====== End Preloader ======-->  */}

            {/* <!--====== Start Header ======--> */}
            <header className="template-header absolute-header navbar-left sticky-header">
                <div className="container">
                    <div className="header-inner">
                        <div className="header-left">
                            <div className="site-logo">
                                <Link to="/Home">
                                    <img src="assets/img/logo/ero_edge_logo.webp" alt="ero_edge_logo" className="custom_logo_design" />
                                </Link>
                            </div>
                            <nav className="nav-menu d-none d-xl-block">
                                <ul className="primary-menu">
                                    <li>
                                        <Link to="/Home" >Home</Link>
                                    </li>
                                    <li>
                                        <Link to="#" className="active">Services</Link>
                                        <ul className="submenu">
                                            <li><Link to="/Software">Software Development</Link></li>
                                            <li><Link to="/Web">Web Application Development</Link></li>
                                            <li><Link to="/Mobile">Mobile Application Development</Link></li>
                                            <li><Link to="/AI">Artificial Intelligence (AI)</Link></li>
                                            <li><Link to="/ML">Machine Learning (ML)</Link></li>
                                            <li><Link to="/Digital">Digital Marketing</Link></li>
                                        </ul>
                                    </li>
                                    <li>
                                        <Link to="/Careers">Careers</Link>
                                    </li>
                                    <li>
                                        <Link to="/About">About Us</Link>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                        <div className="header-right">
                            <ul className="header-extra">
                                <li className="header-btns d-none d-sm-block">
                                    <Link to="/Contact" className="template-btn">
                                        Contact Us
                                        <i className="far fa-long-arrow-right"></i>
                                    </Link>
                                </li>

                                <li className="d-xl-none">
                                    {/* Mobile-menu */}
                                    <div className="#" onClick={toggleMenu}>
                                        <div className="navbar-toggler hamburger_align">
                                            <span className="hamburger_span_align"></span>
                                            <span></span>
                                            <span></span>
                                        </div>
                                    </div>
                                    {isMenuOpen && (
                                        <div className=" mobile-slide-panel-1 slide-panel-1 ">
                                            <div className="panel-inner">
                                                <div className="custom-close-button" onClick={closeMenu}>
                                                    <i className="fal fa-times"></i>
                                                </div>
                                                <div className="custom-panel-logo">
                                                    <img src="assets/img/logo/ero_edge_logo.webp" alt="ero_edge_logo" className="custom_logo_menu_design" />
                                                </div>
                                                <div>
                                                    <nav className="mobile-menu">
                                                        <ul>
                                                            <li>
                                                                <Link to="/Home">Home</Link>
                                                            </li>
                                                            <li>
                                                                <Link to="#" onClick={toggleSubMenu}>Services</Link>
                                                                {isSubMenuOpen && (
                                                                    <ul className="custom-submenu">
                                                                        <li><Link to="/Software">Software Development</Link></li>
                                                                        <li><Link to="/Web">Web Application Development</Link></li>
                                                                        <li><Link to="/Mobile">Mobile Application Development</Link></li>
                                                                        <li><Link to="/AI" className="active">Artificial Intelligence(AI)</Link></li>
                                                                        <li><Link to="/ML">Machine Learning (ML)</Link></li>
                                                                        <li><Link to="/Digital">Digital Marketing</Link></li>
                                                                    </ul>
                                                                )}
                                                            </li>
                                                            <li>
                                                                <Link to="/Careers">Careers</Link>
                                                            </li>
                                                            <li>
                                                                <Link to="/About">About us</Link>
                                                            </li>
                                                        </ul>
                                                        <div>
                                                            <Link to="/Contact" className="template-btn hamburger_contact_btn_align">
                                                                Contact Us
                                                                <i className="far fa-long-arrow-right"></i>
                                                            </Link>
                                                        </div>
                                                    </nav>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </header>
            {/* <!--====== End Header ======-->
        
            <!--====== Page Title Area Start ======--> */}
            <section className="page-title-area">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <h2 className="page-title">Artificial Intelligence</h2>
                        </div>
                        <div className="col-md-6">
                            <ul className="breadcrumb-nav">
                                <li><Link to="/Home">Home</Link></li>
                                <li>AI</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!--====== Page Title Area End ======-->
        
            <!--====== Feature Section Start ======--> */}
            <section className="feature-section section-gap bg-cover-center">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-lg-6 col-md-10">
                            <div className="feature-text-block content-l-spacing">
                                <div className="section-heading mb-30">
                                    <h2 className="title">We’re Very Experience In AI</h2>
                                    <span className="tagline"> Unlock the potential of Artificial Intelligence with Ero Edge Technologies. Our AI Services are designed to revolutionize your business processes, bringing intelligence and efficiency to the forefront of your operations. </span>
                                </div>
                                <div className="feature-lists">
                                    <div className="simple-icon-box icon-left mb-30">
                                        <div className="icon">
                                            <img src="assets/img/icon/approach.svg" alt="approach" className="custom_img_icon_1" />
                                        </div>
                                        <div className="content">
                                            <h4 className="title">Our Approach</h4>
                                            <p>At Ero Edge, we embrace a forward-thinking approach to AI, combining innovation with practical applications. From machine learning algorithms to natural language processing, we harness the power of AI to solve complex problems and drive transformative change.</p>
                                        </div>
                                    </div>
                                    <div className="simple-icon-box icon-left mb-30">
                                        <div className="icon">
                                            <img src="assets/img/icon/technology.svg" alt="approach" className="custom_img_icon_1" />
                                        </div>
                                        <div className="content">
                                            <h4 className="title">Technological Expertise</h4>
                                            <p>Our team of AI specialists is proficient in a range of AI technologies and frameworks, ensuring that our solutions are not just cutting-edge but also tailored to your specific needs.</p>
                                        </div>
                                    </div>
                                    <div className="simple-icon-box icon-left mb-30">
                                        <div className="icon">
                                            <img src="assets/img/icon/industrial.svg" alt="approach" className="custom_img_icon_1" />
                                        </div>
                                        <div className="content">
                                            <h4 className="title">Industries We Serve</h4>
                                            <p>Our AI solutions have made a significant impact across industries, including healthcare, finance, retail, and more. We understand the unique challenges each sector faces and customize our AI services accordingly.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-10">
                            <div className="feature-img text-lg-left text-center content-mb-md-50" style={{ paddingTop: "20px", marginBottom: "-20px" }} >
                                <img src="assets/img/service_images/ai_first.svg" alt="artificial_intelligence_first" className="animate-float-bob-y service_img_align" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!--====== Feature Section End ======--> */}


            {/* <!--====== Service Section Start ======--> */}
            <section className="service-section  section-gap bg-color-primary-7 bg-cover-center" style={{ backgroundImage: "url(assets/img/hero/hero-bg-three.jpg)" }}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="section-heading mb-30 text center">
                            <h2 className="title">Services Offered</h2>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-lg-4 col-md-8 col-sm-8 col-11">
                                <div className="about-contant content-mb-lg-80">
                                    <div className="service-illustration pr-lg-4 content-mb-md-50" style={{ marginTop: "30px", marginBottom: "-60px" }}>
                                        <img src="assets/img/service_images/ml.svg" alt="machine_learning" className="animate-float-bob-x service_img_align" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-8">
                                <div className="service-boxes justify-content-center custom_row">
                                    <div className="col-md-6 col-sm-6 wow fadeInUp custom_card_align">
                                        <div className="iconic-box custom-iconic-box icon-left mt-30 color-4">
                                            <div className="icon">
                                                <img src="assets/img/icon/service5.svg" alt="service5" />
                                            </div>
                                            <div className="content ">
                                                <h5 className="title">Machine Learning Solutions</h5>
                                                <p>Crafting intelligent systems that learn and adapt over time.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-6 wow fadeInUp custom_card_align">
                                        <div className="iconic-box icon-left mt-30">
                                            <div className="icon">
                                                <img src="assets/img/icon/nlp.svg" alt="nlp" />
                                            </div>
                                            <div className="content">
                                                <h4 className="title">Natural Language Processing</h4>
                                                <p>Enhancing communication between machines and humans.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-6 wow fadeInUp custom_card_align" >
                                        <div className="iconic-box icon-left mt-30">
                                            <div className="icon">
                                                <img src="assets/img/icon/laptop.png" alt="laptop" />
                                            </div>
                                            <div className="content">
                                                <h4 className="title">Computer Vision</h4>
                                                <p>Enabling machines to interpret and understand visual information.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br></br>
                <br></br>
                <div className="container">
                    <div className="section-heading text-center mb-30">
                        <h2 className="title">Key Features</h2>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-5 col-sm-6 wow fadeInUp custom_card_align" >
                            <div className="iconic-box icon-left mt-30">
                                <div className="icon">
                                    <img src="assets/img/icon/data_driven.svg" alt="data_driven" />
                                </div>
                                <div className="content">
                                    <h5 className="title">Data-driven Insights</h5>
                                    <p>Leverage the power of data to gain valuable insights and make informed decisions.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 col-sm-6 wow fadeInUp custom_card_align">
                            <div className="iconic-box icon-left mt-30">
                                <div className="icon">
                                    <img src="assets/img/icon/automations.png" alt="automation" />
                                </div>
                                <div className="content">
                                    <h4 className="title">Automation</h4>
                                    <p>Streamline processes and increase efficiency through AI-driven automation.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 col-sm-6 wow fadeInUp custom_card_align">
                            <div className="iconic-box icon-left mt-30">
                                <div className="icon">
                                    <img src="assets/img/icon/customize.svg" alt="customize" />
                                </div>
                                <div className="content">
                                    <h4 className="title">Personalization</h4>
                                    <p>Create personalized experiences for your users based on AI-driven predictions.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br></br>
                {/* <!--====== Service Section-2 Start ======--> */}
                <div className="container" style={{ marginBottom: "-40px" }}>
                    <div className="row justify-content-center">
                        <div className="col-lg-10 col-md-8" style={{ marginBottom: "-20px", marginTop: "20px" }}>
                            <div className="about-contant content-mb-lg-80">
                                <div className="section-heading mb-20">
                                    <h2 className="title">Why Choose Ero Edge for AI Services</h2>
                                </div>
                                <ul className="circle-list mb-40 mt-30 wow fadeInUp">
                                    <li>Innovative Solutions</li>
                                    <p>Stay at the forefront of technological advancements with our innovative AI services.</p>
                                    <br></br>
                                    <li>Proven Expertise</li>
                                    <p>Explore our successful AI projects and see the transformative results we've achieved.</p>
                                    <br></br>
                                    <li>Collaborative Approach </li>
                                    <p>We work closely with clients, ensuring that our AI solutions align with their business goals and objectives</p>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!--====== Service  Section-2 End ======--> */}
            </section>
            {/* <!--====== Service Section End ======--> */}

            {/* <!--====== Call To Action Start ======--> */}
            <section className="call-to-action bg-color-secondary section-animate-icons">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-lg-5 order-lg-last">
                            <div className="cta-illustrations text-center text-lg-right content-mb-md-50">
                                <img src="assets/img/illustrations/cta-illustrations.png" alt="cta-illustrations" />
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-10">
                            <div className="cta-content">
                                <h2 className="title">Ready to Embrace the Future with AI ? </h2>
                                <p className="subtitle">Contact us today to discuss your AI needs and discover how our expertise can elevate your business through the power of Artificial Intelligence.</p>
                                <Link to="/About" className="template-btn">Get in Touch ! <i className="far fa-long-arrow-right"></i></Link>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <!-- Animate Icons --> */}
                <div className="animate-icons">
                    <img src="assets/img/shape/dots-circle.png" alt="Icon" className="dots-circle" />
                </div>
                <div className="cta-shape">
                    <img src="assets/img/shape/cta-shape.png" alt="Shape" />
                </div>
            </section>
            {/* <!--====== Call To Action End ======-->
        
            <!--====== Template Footer Start ======--> */}
            <footer className="template-footer">
                <div className="container">
                    <div className="footer-widgets-area">
                        <div className="row">
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <div className="widget contact-widget">
                                    <h4 className="widget-title">Contact Us</h4>
                                    <div className="contact-content">
                                        <div className="icon">
                                            <p>
                                                <i className="fal fa-map-marker-alt">
                                                </i> 483, 3rdFloor, Block.No:18<br /> Laakini Towers, Vasuki Street,<br />Erode.
                                            </p>
                                            <p>
                                                <Link to="mailto:support@eroedgetechnologies.com">
                                                    <i className="fal fa-envelope-open">
                                                    </i> support@eroedge<br />technologies.com
                                                </Link>
                                            </p>
                                            <p>
                                                <i className="fal fa-phone">
                                                </i> +91 9698143213
                                            </p>

                                        </div>
                                        <img src="assets/img/logo/ero_edge_logo.webp" alt="ero_edge_logo" className="custom_logo_footer_design" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="widget nav-widget pl-lg-4">
                                    <h4 className="widget-title">Quick Link</h4>
                                    <ul>
                                        <li><Link to="/Software">Software Development</Link></li>
                                        <li><Link to="/Web">Web Application Development</Link></li>
                                        <li><Link to="/Mobile">Mobile Application Development</Link></li>
                                        <li><Link to="/ML">Machine Learning (ML)</Link></li>
                                        <li><Link to="/Digital">Digital Marketing</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-6 col-sm-6">
                                <div className="widget nav-widget">
                                    <h4 className="widget-title">Support</h4>
                                    <ul>
                                        <li><Link to="/About">About Us</Link></li>
                                        <li><Link to="/Privacy">Privacy & Policy</Link></li>
                                        <li><Link to="/Careers">Need a Career?</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="widget">
                                    <h4 className="widget-title">Follow</h4>
                                    <ul className="social-links">
                                        <li><Link to="https://www.linkedin.com/company/ero-edge-technologies-private-limited"><i className="fab fa-linkedin"></i></Link></li>
                                        <li><Link to="#"><i className="fab fa-facebook-square"></i></Link></li>
                                        <li><Link to="#"><i className="fab fa-instagram"></i></Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="copyright-box">
                        <p>@ 2024 Ero Edge Technologies Pvt Ltd</p>
                    </div>
                </div>
            </footer>
            {/* <!--====== Template Footer End ======--> */}

        </div>

    );
}

export default AI;