import React from "react";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

function Privacy() {

    const [isMenuOpen, setMenuOpen] = useState(false);
    const [isSubMenuOpen, setSubMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!isMenuOpen);
        // Close submenu when main menu is toggled
        setSubMenuOpen(false);
    };

    const toggleSubMenu = () => {
        setSubMenuOpen(!isSubMenuOpen);
    };
    const closeMenu = () => {
        setMenuOpen(false);
    };

    /*landing page opens at the top of the page*/
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    /* Title page and meta description content has been load from here for their rspective page */
    useEffect(() => {
        document.title = "Ero Edge Technologies Privacy Policy";
        const metaDescriptionTag = document.querySelector('meta[name="description"]');
        if (metaDescriptionTag) {
            metaDescriptionTag.setAttribute('content', 'Learn how Ero Edge Technologies collects, uses, and protects your personal information. Our commitment to your privacy is outlined in this policy.');
        }
    }, []);

    return (

        <div className="restrict_content">
            {/*<!--====== Start Header ======--> */}
            <header className="template-header absolute-header navbar-left header-gradient-bg sticky-header">
                <div className="container">
                    <div className="header-inner">
                        <div className="header-left">
                            <div className="site-logo">
                                <Link to="/Home">
                                    <img src="assets/img/logo/ero_edge_logo.webp" alt="ero_edge_logo" className="custom_logo_design" />
                                </Link>
                            </div>
                            <nav className="nav-menu d-none d-xl-block">
                                <ul className="primary-menu">
                                    <li>
                                        <Link to="/Home">Home</Link>
                                    </li>
                                    <li>
                                        <Link to="#">Services</Link>
                                        <ul className="submenu">
                                            <li><Link to="/Software">Software Development</Link></li>
                                            <li><Link to="/Web">Web Application Development</Link></li>
                                            <li><Link to="/Mobile">Mobile Application Development</Link></li>
                                            <li><Link to="/AI">Artificial Intelligence (AI)</Link></li>
                                            <li><Link to="/ML">Machine Learning (ML)</Link></li>
                                            <li><Link to="/Digital">Digital Marketing</Link></li>
                                        </ul>
                                    </li>
                                    <li>
                                        <Link to="/Careers">Careers</Link>
                                    </li>
                                    <li>
                                        <Link to="/About">About Us</Link>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                        <div className="header-right">
                            <ul className="header-extra">
                                <li className="header-btns d-none d-sm-block">
                                    <Link to="/Contact" className="template-btn">
                                        Contact Us
                                        <i className="far fa-long-arrow-right"></i>
                                    </Link>
                                </li>

                                <li className="d-xl-none">
                                    {/* Mobile-menu */}
                                    <div className="#" onClick={toggleMenu}>
                                        <div className="navbar-toggler hamburger_align">
                                            <span className="hamburger_span_align"></span>
                                            <span></span>
                                            <span></span>
                                        </div>
                                    </div>
                                    {isMenuOpen && (
                                        <div className=" mobile-slide-panel-1 slide-panel-1 ">
                                            <div className="panel-inner">
                                                <div className="custom-close-button" onClick={closeMenu}>
                                                    <i className="fal fa-times"></i>
                                                </div>
                                                <div className="custom-panel-logo">
                                                    <img src="assets/img/logo/ero_edge_logo.webp" alt="ero_edge_logo" className="custom_logo_menu_design" />
                                                </div>
                                                <div>
                                                    <nav className="mobile-menu">
                                                        <ul>
                                                            <li>
                                                                <Link to="/Home">Home</Link>
                                                            </li>
                                                            <li>
                                                                <Link to="#" onClick={toggleSubMenu}>Services</Link>
                                                                {isSubMenuOpen && (
                                                                    <ul className="custom-submenu">
                                                                        <li><Link to="/Software">Software Development</Link></li>
                                                                        <li><Link to="/Web">Web Application Development</Link></li>
                                                                        <li><Link to="/Mobile">Mobile Application Development</Link></li>
                                                                        <li><Link to="/AI">Artificial Intelligence(AI)</Link></li>
                                                                        <li><Link to="/ML">Machine Learning (ML)</Link></li>
                                                                        <li><Link to="/Digital">Digital Marketing</Link></li>
                                                                    </ul>
                                                                )}
                                                            </li>
                                                            <li>
                                                                <Link to="/Careers">Careers</Link>
                                                            </li>
                                                            <li>
                                                                <Link to="/About">About us</Link>
                                                            </li>
                                                        </ul>
                                                        <div>
                                                            <Link to="/Contact" className="template-btn hamburger_contact_btn_align">
                                                                Contact Us
                                                                <i className="far fa-long-arrow-right"></i>
                                                            </Link>
                                                        </div>
                                                    </nav>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </header>
            {/* <!--====== End Header ======-->*/}

            {/* <!--====== Privacy policy start ======--> */}
            <section className="custom_hero_area_one privacy_bottom_align">
                <div className="container">
                    <div className="justify-content-center">
                        <div className="hero-contet">
                            <div>
                                <h3>Disclaimer</h3>
                                <br></br>
                                <p>This Portal/Website & contents of this Portal/Website are owned,
                                    updated and maintained by Ero Edge Technologies Private Limited,
                                    India (here in referred as Ero Edge) and hosted by Ero Edge for general information purpose.
                                </p>
                                <br></br>
                                <p>
                                    Though all efforts have been made to ensure the accuracy and currency of the content on this Portal,
                                    the same should not be construed as a statement of law or used for any legal purposes.
                                    Ero Edge accepts no responsibility in relation to the accuracy, completeness, usefulness or otherwise, of the contents.
                                </p>
                                <br></br>
                                <p>
                                    Links to other websites that have been included on this Portal/Website are provided for public convenience only.
                                    Ero Edge is not responsible for the contents or reliability of linked websites and does not necessarily endorse the view expressed within them.
                                    We cannot guarantee the availability of such linked pages at all times.
                                </p>
                            </div>
                            <   br></br>
                            <div>
                                <h3>Privacy Policy</h3>
                                <br></br>
                                <p>
                                    The Portal/Website does not automatically capture any specific personal information from you,
                                    (like name, phone number or e-mail address), that allows us to identify you individually.
                                    However, if the Portal/Website requests you to provide personal information,
                                    you will be informed for the particular purposes for which the information is gathered and adequate security measures will be taken to protect your personal information.
                                </p>
                                <br></br>
                                <p>
                                    We do not sell or share any personally identifiable information volunteered on the Portal/Website to any third party (public/private).
                                    Any information provided to this Portal/Website will be protected from loss, misuse, unauthorized access or disclosure, alteration, or destruction.
                                </p>
                            </div>
                            <br></br>
                            <div>
                                <h3>Linking Policy – Links to external websites/portals & vice-versa</h3>
                                <br></br>
                                <p>
                                    At some places in this Portal/Website, you shall find links to other websites/portals. These links have been placed for your convenience.
                                    Ero Edge is not responsible for the contents and reliability of the linked websites and does not necessarily endorse the views expressed in them.
                                    Mere presence of the link or its listing on this Portal should not be assumed as endorsement of any kind.
                                    We cannot guarantee that these links will work all the time and we have no control over availability of linked pages.
                                </p>
                                <br></br>
                                <p>
                                    Concurrently, we do not object to you linking directly to the information that is hosted on Portal/Website and no prior permission is required for the same.
                                    However, we would like you to inform us about any links provided to this Portal/Website so that you can be informed of any changes or updates there in.
                                    Also, we do not permit our pages to be loaded into frames on your site.The pages belonging to this Portal/Website must load into a newly opened browser window of the User.
                                </p>
                            </div>
                            <br></br>
                            <div>
                                <h3>Copyrights & Trademarks</h3>
                                <br></br>
                                <p>
                                    Any of the Copyrights/Trademarks, logos, service marks, collective marks, design rights or similar rights that are mentioned, used or cited in this website are the property of their respective owners.
                                    Their use here does not imply that you may use them for any other purpose other than for the same or a similar informational use as contemplated by Ero Edge.
                                    Your use of any such or similar incorporeal property is at your own risk.
                                </p>
                                <br></br>
                                <p>
                                    At Ero Edge, we do our very best to provide accurate and current information, however we are human and stuff happens.
                                    Don’t worry, we’ve got your back. Your information is safe with us. We know how important your privacy is and we will protect it.
                                </p>
                                <br></br>
                                <p>
                                    A we will do our best to make sure we don’t leave you hanging and provide you with valuable information on all our web pages-so don’t be shy linking directly to our website,
                                    we are totally cool with that.
                                </p>
                                <br></br>
                                <p>
                                    Some stuff belongs to our clients. Imitation or manipulation of them is frowned upon.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <!--====== Privacy policy End ======--> */}

            {/*<!--====== Template Footer Start ======--> */}
            <footer className="template-footer bg-color-secondary text-white-version">
                <div className="container">
                    <div className="footer-widgets-area">
                        <div className="row">
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <div className="widget contact-widget">
                                    <h4 className="widget-title">Contact Us</h4>
                                    <div className="contact-content">
                                        <div className="icon">
                                            <p>
                                                <i className="fal fa-map-marker-alt">
                                                </i> 483, 3rdFloor, Block.No:18<br /> Laakini Towers, Vasuki Street,<br />Erode.
                                            </p>
                                            <p>
                                                <Link to="mailto:support@eroedgetechnologies.com">
                                                    <i className="fal fa-envelope-open">
                                                    </i> support@eroedge<br />technologies.com
                                                </Link>
                                            </p>
                                            <p>
                                                <i className="fal fa-phone">
                                                </i> +91 9698143213
                                            </p>

                                        </div>
                                        <img src="assets/img/logo/ero_edge_logo_1.png" alt="ero_edge_logo" className="custom_logo_footer_design" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="widget nav-widget pl-lg-4">
                                    <h4 className="widget-title">Quick Link</h4>
                                    <ul>
                                        <li><Link to="/Software">Software Development</Link></li>
                                        <li><Link to="/Web">Web Application Development</Link></li>
                                        <li><Link to="/Mobile">Mobile Application Development</Link></li>
                                        <li><Link to="/AI">Artificial Intelligence (AI)</Link></li>
                                        <li><Link to="/ML">Machine Learning (ML)</Link></li>
                                        <li><Link to="/Digital">Digital Marketing</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-6 col-sm-6">
                                <div className="widget nav-widget">
                                    <h4 className="widget-title">Support</h4>
                                    <ul>
                                        <li><Link to="/About">About Us</Link></li>
                                        <li><Link to="/Careers">Need a Career?</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="widget">
                                    <h4 className="widget-title">Follow</h4>
                                    <ul className="social-links">
                                        <li><Link to="https://www.linkedin.com/company/ero-edge-technologies-private-limited"><i className="fab fa-linkedin"></i></Link></li>
                                        <li><Link to="https://www.facebook.com/p/Ero-Edge-Technologies-Private-Limited-61556438934930/"><i className="fab fa-facebook-square"></i></Link></li>
                                        <li><Link to="https://www.instagram.com/ero_edge_technologies/"><i className="fab fa-instagram"></i></Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="copyright-box bg-color-primary">
                        <p className="privacy_footer_text">@ 2024 Ero Edge Technologies Pvt Ltd</p>
                    </div>
                </div>
            </footer>
            {/* <!--====== Template Footer End ======--> */}

        </div>




    );
}

export default Privacy;