import React from "react";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import Preloaders from "../components/Preloaders";


function Software() {

    const [isMenuOpen, setMenuOpen] = useState(false);
    const [isSubMenuOpen, setSubMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!isMenuOpen);
        // Close submenu when main menu is toggled
        setSubMenuOpen(false);
    };

    const toggleSubMenu = () => {
        setSubMenuOpen(!isSubMenuOpen);
    };
    const closeMenu = () => {
        setMenuOpen(false);
    };

    /*landing page opens at the top of the page*/
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    /* Title page and meta description content has been load from here for their rspective page */
    useEffect(() => {
        document.title = "From Idea to Reality: Software Development with Ero Edge Technologies";
        const metaDescriptionTag = document.querySelector('meta[name="description"]');
        if (metaDescriptionTag) {
            metaDescriptionTag.setAttribute('content', 'Looking for scalable software solutions that adapt to your growing business? Ero Edge offers proven expertise in developing robust and flexible software applications.');
        }
    }, []);

    return (


        <div className="restrict_content">
            {/* <!--====== Start Preloader ======--> */}
            <Preloaders />
            {/* <!--====== End Preloader ======-->  */}

            {/* <!--====== Start Header ======--> */}
            <header className="template-header absolute-header navbar-left sticky-header">
                <div className="container">
                    <div className="header-inner">
                        <div className="header-left">
                            <div className="site-logo">
                                <Link to="/Home">
                                    <img src="assets/img/logo/ero_edge_logo.webp" alt="ero_edge_logo" className="custom_logo_design" />
                                </Link>
                            </div>
                            <nav className="nav-menu d-none d-xl-block">
                                <ul className="primary-menu">
                                    <li>
                                        <Link to="/Home">Home</Link>
                                    </li>
                                    <li>
                                        <Link to="#" className="active">Services</Link>
                                        <ul className="submenu">
                                            <li><Link to="/Software">Software Development</Link></li>
                                            <li><Link to="/Web">Web Application Development</Link></li>
                                            <li><Link to="/Mobile">Mobile Application Development</Link></li>
                                            <li><Link to="/AI">Artificial Intelligence(AI)</Link></li>
                                            <li><Link to="/ML">Machine Learning (ML)</Link></li>
                                            <li><Link to="/Digital">Digital Marketing</Link></li>
                                        </ul>
                                    </li>
                                    <li>
                                        <Link to="/Careers">Careers</Link>
                                    </li>
                                    <li>
                                        <Link to="/About">About Us</Link>
                                    </li>

                                </ul>
                            </nav>
                        </div>
                        <div className="header-right">
                            <ul className="header-extra">
                                <li className="header-btns d-none d-sm-block">
                                    <Link to="/Contact" className="template-btn">
                                        Contact Us
                                        <i className="far fa-long-arrow-right"></i>
                                    </Link>
                                </li>

                                <li className="d-xl-none">
                                    {/* Mobile-menu */}
                                    <div className="#" onClick={toggleMenu}>
                                        <div className="navbar-toggler hamburger_align">
                                            <span className="hamburger_span_align"></span>
                                            <span></span>
                                            <span></span>
                                        </div>
                                    </div>
                                    {isMenuOpen && (
                                        <div className=" mobile-slide-panel-1 slide-panel-1 ">
                                            <div className="panel-inner">
                                                <div className="custom-close-button" onClick={closeMenu}>
                                                    <i className="fal fa-times"></i>
                                                </div>
                                                <div className="custom-panel-logo">
                                                    <img src="assets/img/logo/ero_edge_logo.webp" alt="ero_edge_logo" className="custom_logo_menu_design" />
                                                </div>
                                                <div>
                                                    <nav className="mobile-menu">
                                                        <ul>
                                                            <li>
                                                                <Link to="/Home">Home</Link>
                                                            </li>
                                                            <li>
                                                                <Link to="#" onClick={toggleSubMenu}>Services</Link>
                                                                {isSubMenuOpen && (
                                                                    <ul className="custom-submenu">
                                                                        <li><Link to="/Software" className="active">Software Development</Link></li>
                                                                        <li><Link to="/Web">Web Application Development</Link></li>
                                                                        <li><Link to="/Mobile">Mobile Application Development</Link></li>
                                                                        <li><Link to="/AI">Artificial Intelligence(AI)</Link></li>
                                                                        <li><Link to="/ML">Machine Learning (ML)</Link></li>
                                                                        <li><Link to="/Digital">Digital Marketing</Link></li>
                                                                    </ul>
                                                                )}
                                                            </li>
                                                            <li>
                                                                <Link to="/Careers">Careers</Link>
                                                            </li>
                                                            <li>
                                                                <Link to="/About">About us</Link>
                                                            </li>
                                                        </ul>
                                                        <div>
                                                            <Link to="/Contact" className="template-btn hamburger_contact_btn_align">
                                                                Contact Us
                                                                <i className="far fa-long-arrow-right"></i>
                                                            </Link>
                                                        </div>
                                                    </nav>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </header>
            {/* <!--====== End Header ======-->
        
            <!--====== Page Title Area Start ======--> */}
            <section className="page-title-area">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <h2 className="page-title">Software Development</h2>
                        </div>
                        <div className="col-md-6">
                            <ul className="breadcrumb-nav">
                                <li><Link to="/Home">Home</Link></li>
                                <li>Software Development</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!--====== Page Title Area End ======-->
        
            <!--====== Service Area Started ======--> */}
            <section className="service-top-area section-gap">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-lg-6 col-md-9 col-sm-10">
                            <div className="service-illustration pr-lg-4 content-mb-md-50">
                                <img src="assets/img/service_images/software_development.svg" alt="software_development" className="animate-float-bob-y" style={{ marginTop: '-30px', marginBottom: '-40px' }} />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-10">
                            <div className="service-top-content pl-xl-4">
                                <div className="section-heading mb-50">
                                    <h2 className="title">We’re Very Experience In Software Development</h2>
                                    <span className="tagline">Welcome to our Software Development Services – where ideas transform into powerful digital solutions. At Ero Edge Technologies, we specialize in crafting custom software tailored to your unique business needs.</span>
                                </div>
                                <ul className="icon-bordered-list">
                                    <li className="wow fadeInUp" data-wow-delay="0.3s">
                                        <div className="icon">
                                            <img src="assets/img/icon/approach.svg" alt="approach" className="custom_img_icon" />
                                        </div>
                                        <div className="content">
                                            <h5 className="title">Our Approach </h5>
                                            <p>Our development approach is centered on collaboration and innovation. We work closely with clients to understand their objectives, ensuring the final product not only meets but exceeds expectations.</p>
                                        </div>
                                    </li>
                                    <li className="wow fadeInUp" data-wow-delay="0.4s">
                                        <div className="icon">
                                            <img src="assets/img/icon/technology.svg" alt="technology" className="custom_img_icon" />
                                        </div>
                                        <div className="content">
                                            <h5 className="title">Technological Expertise </h5>
                                            <p>We are proficient in a wide array of technologies, ensuring that our software solutions are not only robust but also at the forefront of industry standards.</p>
                                        </div>
                                    </li>
                                    <li className="wow fadeInUp" data-wow-delay="0.5s">
                                        <div className="icon">
                                            <img src="assets/img/icon/industrial.svg" alt="industrial" className="custom_img_icon" />
                                        </div>
                                        <div className="content">
                                            <h5 className="title">Industries We Serve </h5>
                                            <p>Our diverse portfolio spans across industries, from healthcare and finance to e-commerce and beyond. We bring our expertise to various sectors, solving unique challenges with creative software solutions.</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!--====== Service Area End ======-->
        
            <!--====== Process Section Start ======--> */}
            <section className="process-section section-gap bg-cover-center" style={{ backgroundImage: "url(assets/img/hero/hero-bg-two.jpg)" }}>
                <div className="container">
                    <div className="section-heading mb-50 text-center">
                        <h2 className="title">Software Development</h2>
                    </div>
                    <div className="process-boxes">
                        <div className="single-process-box wow fadeInUp" data-wow-delay="0.3s">
                            <div className="row align-items-center justify-content-center">
                                <div className="col-lg-6 col-sm-10">
                                    <div className="process-img">
                                        <img src="assets/img/illustrations/process-1.png" alt="" />
                                    </div>
                                </div>
                                <div className="section-heading text-center mb-30">
                                    <h2 className="title">Services Offered</h2>
                                </div>
                                <div className="service-boxes justify-content-center custom_row">
                                    <div className="col-lg-4 col-md-6 col-sm-8 col-11 wow fadeInUp custom_software_card_align" data-wow-delay="0.1s">
                                        <div className="fancy-iconic-box no-hover-effect mt-30">
                                            <h4 className="title">Full-Cycle Development </h4>
                                            <p>From concept to deployment, we manage every phase of the software development life cycle.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-8 col-11 wow fadeInUp custom_software_card_align" data-wow-delay="0.2s">
                                        <div className="fancy-iconic-box no-hover-effect mt-30">
                                            <h4 className="title">Custom Solutions </h4>
                                            <p>Tailored software that aligns perfectly with your business goals and processes.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-8 col-11 wow fadeInUp custom_software_card_align" data-wow-delay="0.3s">
                                        <div className="fancy-iconic-box no-hover-effect mt-30">
                                            <h4 className="title">Legacy System Upgrades</h4>
                                            <p>Modernizing and enhancing outdated systems for improved performance and functionality.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="single-process-box wow fadeInUp" data-wow-delay="0.3s">
                            <div className="row align-items-center justify-content-center">
                                <div className="col-lg-6 col-sm-10 order-lg-last">
                                    <div className="process-img">
                                        <img src="assets/img/illustrations/process-3.png" alt="" />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="process-content">
                                        <h2 className="title">
                                            Key Features
                                        </h2>
                                        <p>
                                            <b>Scalability </b>Our solutions are designed to grow with your business, ensuring long-term relevance.
                                        </p>
                                        <p>
                                            <b>Security </b>Robust security measures to protect your data and ensure user privacy.
                                        </p>
                                        <p>
                                            <b>User-Centric Design </b>Intuitive interfaces for an exceptional user experience.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!--====== Process Section End ======--> */}

            {/* <!--====== service section-2 start ======--> */}
            <section className="call-to-action bg-color-primary section-animate-icons">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-lg-4 ">
                            <div className="text-center text-lg-right content-mb-md-50">
                                <img src="assets/img/illustrations/process-2.png" alt="illustrations" className="animate-float-bob-x" />
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-10">
                            <div className="cta-content">
                                <h2 className="title"> Why Choose Ero Edge for Software Development</h2>
                                <br></br>
                                <ul className=" wow fadeInUp">
                                    <h3 className="custom-title">Proven Track Record </h3>
                                    <p className="custom-cta-note"> Check out our successful projects and satisfied client testimonials.</p>
                                    <br></br>
                                    <h3 className="custom-title">Innovation</h3>
                                    <p className="custom-cta-note">We stay abreast of the latest technologies to bring innovation to your projects.</p>
                                    <br></br>
                                    <h3 className="custom-title">Client-Centric Approach</h3>
                                    <p className="custom-cta-note"> Your vision is our priority, and we're dedicated to delivering solutions that align with your goals.</p>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <!-- Animate Icons --> */}
                <div className="cta-shape">
                    <img src="assets/img/shape/blob-white.png" alt="Shape" />
                </div>
            </section>
            {/* <!--====== service section-2 End ======--> */}



            {/* <!--====== Call To Action Start ======--> */}
            <section className="call-to-action bg-color-secondary section-animate-icons">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-lg-5 order-lg-last">
                            <div className="cta-illustrations text-center text-lg-right content-mb-md-50">
                                <img src="assets/img/illustrations/cta-illustrations.png" alt="cta-illustrations" />
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-10">
                            <div className="cta-content">
                                <h2 className="title"> Ready to Transform Your Ideas into Reality ?</h2>
                                <p className="subtitle">Contact us today to discuss your software development needs and embark on a journey to digital excellence.</p>
                                <Link to="/About" className="template-btn">Get in Touch ! <i className="far fa-long-arrow-right"></i></Link>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <!-- Animate Icons --> */}
                <div className="animate-icons">
                    <img src="assets/img/shape/dots-circle.png" alt="Icon" className="dots-circle" />
                </div>
                <div className="cta-shape">
                    <img src="assets/img/shape/cta-shape.png" alt="Shape" />
                </div>
            </section>
            {/* <!--====== Call To Action End ======-->
        
            <!--====== Template Footer Start ======--> */}
            <footer className="template-footer">
                <div className="container">
                    <div className="footer-widgets-area">
                        <div className="row">
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <div className="widget contact-widget">
                                    <h4 className="widget-title">Contact Us</h4>
                                    <div className="contact-content">
                                        <div className="icon">
                                            <p>
                                                <i className="fal fa-map-marker-alt">
                                                </i> 483, 3rdFloor, Block.No:18<br /> Laakini Towers, Vasuki Street,<br />Erode.
                                            </p>
                                            <p>
                                                <Link to="mailto:support@eroedgetechnologies.com">
                                                    <i className="fal fa-envelope-open">
                                                    </i> support@eroedge<br />technologies.com
                                                </Link>
                                            </p>
                                            <p>
                                                <i className="fal fa-phone">
                                                </i> +91 9698143213
                                            </p>

                                        </div>
                                        <img src="assets/img/logo/ero_edge_logo.webp" alt="ero_edge_logo" className="custom_logo_footer_design" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="widget nav-widget pl-lg-4">
                                    <h4 className="widget-title">Quick Link</h4>
                                    <ul>
                                        <li><Link to="/Web">Web Application Development</Link></li>
                                        <li><Link to="/Mobile">Mobile Application Development</Link></li>
                                        <li><Link to="/AI">Artificial Intelligence(AI)</Link></li>
                                        <li><Link to="/ML">Machine Learning (ML)</Link></li>
                                        <li><Link to="/Digital">Digital Marketing</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-6 col-sm-6">
                                <div className="widget nav-widget">
                                    <h4 className="widget-title">Support</h4>
                                    <ul>
                                        <li><Link to="/About">About Us</Link></li>
                                        <li><Link to="/Privacy">Privacy & Policy</Link></li>
                                        <li><Link to="/Careers">Need a Career?</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="widget">
                                    <h4 className="widget-title">Follow</h4>
                                    <ul className="social-links">
                                        <li><Link to="https://www.linkedin.com/company/ero-edge-technologies-private-limited"><i className="fab fa-linkedin"></i></Link></li>
                                        <li><Link to="https://www.facebook.com/p/Ero-Edge-Technologies-Private-Limited-61556438934930/"><i className="fab fa-facebook-square"></i></Link></li>
                                        <li><Link to="https://www.instagram.com/ero_edge_technologies/"><i className="fab fa-instagram"></i></Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="copyright-box">
                        <p>@ 2024 Ero Edge Technologies Pvt Ltd</p>
                    </div>
                </div>
            </footer>
            {/* <!--====== Template Footer End ======--> */}

        </div>

    );
}

export default Software;