import React from "react";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import Preloaders from "../components/Preloaders";


function Web() {

    const [isMenuOpen, setMenuOpen] = useState(false);
    const [isSubMenuOpen, setSubMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!isMenuOpen);
        // Close submenu when main menu is toggled
        setSubMenuOpen(false);
    };

    const toggleSubMenu = () => {
        setSubMenuOpen(!isSubMenuOpen);
    };
    const closeMenu = () => {
        setMenuOpen(false);
    };

    /*landing page opens at the top of the page*/
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    /* Title page and meta description content has been load from here for their rspective page */
    useEffect(() => {
        document.title = "Build a Web Presence that Converts with Cutting-Edge Development";
        const metaDescriptionTag = document.querySelector('meta[name="description"]');
        if (metaDescriptionTag) {
            metaDescriptionTag.setAttribute('content', 'Whether you need a simple website or a complex web app, Ero Edge possesses the expertise to deliver dynamic and engaging online experiences that deliver results.');
        }
    }, []);


    return (


        <div className="restrict_content">
            {/* <!--====== Start Preloader ======--> */}
            <Preloaders />
            {/* <!--====== End Preloader ======-->  */}

            {/* <!--====== Start Header ======--> */}
            <header className="template-header absolute-header navbar-left sticky-header">
                <div className="container">
                    <div className="header-inner">
                        <div className="header-left">
                            <div className="site-logo">
                                <Link to="/Home">
                                    <img src="assets/img/logo/ero_edge_logo.webp" alt="ero_edge_logo" className="custom_logo_design" />
                                </Link>
                            </div>
                            <nav className="nav-menu d-none d-xl-block">
                                <ul className="primary-menu">
                                    <li>
                                        <Link to="/Home">Home</Link>
                                    </li>
                                    <li>
                                        <Link to="#" className="active">Services</Link>
                                        <ul className="submenu">
                                            <li><Link to="/Software">Software Development</Link></li>
                                            <li><Link to="/Web">Web Application Development</Link></li>
                                            <li><Link to="/Mobile">Mobile Application Development</Link></li>
                                            <li><Link to="/AI">Artificial Intelligence(AI)</Link></li>
                                            <li><Link to="/ML">Machine Learning (ML)</Link></li>
                                            <li><Link to="/Digital">Digital Marketing</Link></li>
                                        </ul>
                                    </li>
                                    <li>
                                        <Link to="/Careers">Careers</Link>
                                    </li>
                                    <li>
                                        <Link to="/About">About Us</Link>
                                    </li>

                                </ul>
                            </nav>
                        </div>
                        <div className="header-right">
                            <ul className="header-extra">
                                <li className="header-btns d-none d-sm-block">
                                    <Link to="/Contact" className="template-btn">
                                        Contact Us
                                        <i className="far fa-long-arrow-right"></i>
                                    </Link>
                                </li>
                                <li className="d-xl-none">
                                    {/* Mobile-menu */}
                                    <div className="#" onClick={toggleMenu}>
                                        <div className="navbar-toggler hamburger_align">
                                            <span className="hamburger_span_align"></span>
                                            <span></span>
                                            <span></span>
                                        </div>
                                    </div>
                                    {isMenuOpen && (
                                        <div className=" mobile-slide-panel-1 slide-panel-1 ">
                                            <div className="panel-inner">
                                                <div className="custom-close-button" onClick={closeMenu}>
                                                    <i className="fal fa-times"></i>
                                                </div>
                                                <div className="custom-panel-logo">
                                                    <img src="assets/img/logo/ero_edge_logo.webp" alt="ero_edge_logo" className="custom_logo_menu_design" />
                                                </div>
                                                <div>
                                                    <nav className="mobile-menu">
                                                        <ul>
                                                            <li>
                                                                <Link to="/Home">Home</Link>
                                                            </li>
                                                            <li>
                                                                <Link to="#" onClick={toggleSubMenu}>Services</Link>
                                                                {isSubMenuOpen && (
                                                                    <ul className="custom-submenu">
                                                                        <li><Link to="/Software">Software Development</Link></li>
                                                                        <li><Link to="/Web" className="active">Web Application Development</Link></li>
                                                                        <li><Link to="/Mobile">Mobile Application Development</Link></li>
                                                                        <li><Link to="/AI">Artificial Intelligence(AI)</Link></li>
                                                                        <li><Link to="/ML">Machine Learning (ML)</Link></li>
                                                                        <li><Link to="/Digital">Digital Marketing</Link></li>
                                                                    </ul>
                                                                )}
                                                            </li>
                                                            <li>
                                                                <Link to="/Careers">Careers</Link>
                                                            </li>
                                                            <li>
                                                                <Link to="/About">About us</Link>
                                                            </li>
                                                        </ul>
                                                        <div>
                                                            <Link to="/Contact" className="template-btn hamburger_contact_btn_align">
                                                                Contact Us
                                                                <i className="far fa-long-arrow-right"></i>
                                                            </Link>
                                                        </div>
                                                    </nav>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </header>
            {/* <!--====== End Header ======-->
        
            <!--====== Page Title Area Start ======--> */}
            <section className="page-title-area">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <h2 className="page-title">Web Application Development</h2>
                        </div>
                        <div className="col-md-6">
                            <ul className="breadcrumb-nav">
                                <li><Link to="/Home">Home</Link></li>
                                <li>Web Application Development</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!--====== Page Title Area End ======--> */}

            {/* <!--====== Service Area Started ======--> */}
            <section className="service-top-area section-gap">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-lg-6 col-md-10">
                            <div className="service-top-content pl-xl-4">
                                <div className="section-heading mb-50">
                                    <h2 className="title">We’re Very Experience In Web Application Development</h2>
                                    <span className="tagline">Explore the future of digital interactivity with our Web Application Development Services. At Ero Edge Technologies, we specialize in creating dynamic and feature-rich web applications that elevate your online presence and engage your audience effectively. </span>
                                </div>
                                <ul className="icon-bordered-list ">
                                    <li className="wow fadeInUp" data-wow-delay="0.3s">
                                        <div className="icon">
                                            <img src="assets/img/icon/approach.svg" alt="approach" className="custom_img_icon" />
                                        </div>
                                        <div className="content">
                                            <h5 className="title">Our Approach</h5>
                                            <p>Our web application development process is driven by a commitment to delivering innovative solutions that align with your business objectives. We believe in creating not just applications but immersive experiences for users.</p>
                                        </div>
                                    </li>
                                    <li className="wow fadeInUp" data-wow-delay="0.4s">
                                        <div className="icon">
                                            <img src="assets/img/icon/technology.svg" alt="technology" className="custom_img_icon" />
                                        </div>
                                        <div className="content">
                                            <h5 className="title">Technological Expertise</h5>
                                            <p>We leverage the latest technologies and frameworks to build scalable and robust web applications. Our expertise encompasses a wide range of programming languages, ensuring versatility in our solutions.</p>
                                        </div>
                                    </li>
                                    <li className="wow fadeInUp" data-wow-delay="0.5s">
                                        <div className="icon">
                                            <img src="assets/img/icon/industrial.svg" alt="industrial" className="custom_img_icon" />
                                        </div>
                                        <div className="content">
                                            <h5 className="title">Industries We Serve</h5>
                                            <p>Our web applications have made an impact across various industries, including healthcare, finance, e-commerce, and more. We understand the unique challenges each sector faces and tailor our solutions accordingly.</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-9 col-sm-10">
                            <div className="service-illustration pr-lg-4 content-mb-md-50" style={{ paddingTop: "50px", marginBottom: "-30px" }} >
                                <img src="assets/img/service_images/web_application_development.svg" alt="web_application_development" className="animate-float-bob-y" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!--====== Service Area End ======--> */}


            {/* <!--====== Process Section Start ======--> */}
            <section className="process-section section-gap bg-color-primary-7 bg-cover-center" style={{ backgroundImage: "url(assets/img/hero/service-bg-2.jpg)" }}>
                <div className="container">
                    <div className="section-heading mb-50 text-center">
                        <h2 className="title">Web Application Development</h2>
                    </div>
                    <div className="#">
                        <div className="wow fadeInUp" data-wow-delay="0.3s">
                            <div className="row align-items-center justify-content-center">
                                <section className="service-section">
                                    <div className="container">
                                        <div className="section-heading mb-20">
                                            <h2 className="title">Services Offered</h2>
                                        </div>
                                        <div className="row justify-content-center">
                                            <div className="col-lg-4 col-md-8">
                                                <div className="about-contant content-mb-lg-80" style={{ marginBottom: '30px' }}>
                                                    <div className="process-img">
                                                        <img src="assets/img/illustrations/process-1.png" alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                            <br></br>
                                            <div className="col-lg-8">
                                                <div className="service-boxes justify-content-center custom_row">
                                                    <div className="col-lg-6 col-md-6 col-sm-8 col-11 wow fadeInUp custom_card_align" data-wow-delay="0.1s">
                                                        <div className="fancy-iconic-box mt-30 color-1">
                                                            <h4 className="title">Custom Web Applications </h4>
                                                            <p>Tailored solutions to meet the unique requirements of your business.</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-8 col-11 wow fadeInUp custom_card_align" data-wow-delay="0.2s">
                                                        <div className="fancy-iconic-box mt-30 color-4">
                                                            <h4 className="title">Responsive Design </h4>
                                                            <p>Ensuring seamless user experiences across various devices and screen sizes.</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-8 col-11 wow fadeInUp custom_card_align" data-wow-delay="0.3s">
                                                        <div className="fancy-iconic-box mt-30 color-3">
                                                            <h4 className="title">E-commerce Solutions</h4>
                                                            <p>Powerful and secure platforms for online business success.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <br></br>
                                        <div className="section-heading mb-20">
                                            <h2 className="title "> Key Features</h2>
                                        </div>
                                        <div className="row justify-content-center">
                                            <div className="col-lg-8">
                                                <div className="service-boxes justify-content-center custom_row">
                                                    <div className="col-lg-6 col-md-6 col-sm-8 col-11 wow fadeInUp custom_card_align" data-wow-delay="0.1s">
                                                        <div className="fancy-iconic-box mt-30 color-1">
                                                            <h4 className="title">User-Centric Design </h4>
                                                            <p>Intuitive interfaces that enhance user engagement and satisfaction.</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-8 col-11 wow fadeInUp custom_card_align" data-wow-delay="0.2s">
                                                        <div className="fancy-iconic-box mt-30 color-4">
                                                            <h4 className="title">Scalability </h4>
                                                            <p>Web applications designed to grow with your business and handle increased demand.</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-8 col-11 wow fadeInUp" data-wow-delay="0.3s" style={{ display: 'flex', paddingBottom: '50px' }}>
                                                        <div className="fancy-iconic-box mt-30 color-3">
                                                            <h4 className="title">Security</h4>
                                                            <p>Robust security measures to protect sensitive data and user information.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <br></br>
                                            <div className="col-lg-4 col-md-8 col-sm-8 col-11">
                                                <div className="about-contant content-mb-lg-80">
                                                    <div className="process-img">
                                                        <img src="assets/img/illustrations/process-2.png" alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>

                        {/*<!--====== Process Sectoion-2 Start ======--> */}

                        <section className="call-to-action bg-color-primary section-animate-icons">
                            <div className="container">
                                <div className="row align-items-center justify-content-center">
                                    <div className="col-lg-10 col-md-10">
                                        <div className="cta-content">
                                            <h1 className="#">
                                                Why Choose Ero Edge for Web Application Development
                                            </h1>
                                            <br></br>
                                            <h3>Innovative Solutions </h3>
                                            <p className="custom-cta-note">
                                                We bring creativity and innovation to every web application project.
                                            </p>
                                            <br></br>
                                            <h3>Timely Delivery </h3>
                                            <p className="custom-cta-note">
                                                Our commitment to deadlines ensures your project is delivered on schedule.
                                            </p>
                                            <br></br>
                                            <h3>Customer Satisfaction </h3>
                                            <p className="custom-cta-note">
                                                Satisfied clients speak to the success of our web application development services.
                                            </p>
                                            <br></br>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* <!--====== Process section-2 End ======--> */}
                    </div>
                </div>
            </section>
            {/* <!--====== Process Section End ======--> */}


            {/*<!--====== Call To Action Start ======--> */}
            <section className="call-to-action bg-color-secondary section-animate-icons">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-lg-5 order-lg-last">
                            <div className="cta-illustrations text-center text-lg-right content-mb-md-50">
                                <img src="assets/img/illustrations/cta-illustrations.png" alt="cta-illustrations" />
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-10">
                            <div className="cta-content">
                                <h2 className="title">Ready to Elevate Your Online Presence ?</h2>
                                <p className="subtitle">Contact us today to discuss your web application development needs and discover the potential for digital growth and success.</p>
                                <Link to="/About" className="template-btn">Get in Touch ! <i className="far fa-long-arrow-right"></i></Link>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <!-- Animate Icons --> */}
                <div className="animate-icons">
                    <img src="assets/img/shape/dots-circle.png" alt="Icon" className="dots-circle" />
                </div>
                <div className="cta-shape">
                    <img src="assets/img/shape/cta-shape.png" alt="Shape" />
                </div>
            </section>
            {/* <!--====== Call To Action End ======--> */}

            {/* <!--====== Template Footer Start ======--> */}
            <footer className="template-footer">
                <div className="container">
                    <div className="footer-widgets-area">
                        <div className="row">
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <div className="widget contact-widget">
                                    <h4 className="widget-title">Contact Us</h4>
                                    <div className="contact-content">
                                        <div className="icon">
                                            <p>
                                                <i className="fal fa-map-marker-alt">
                                                </i> 483, 3rdFloor, Block.No:18<br /> Laakini Towers, Vasuki Street,<br />Erode.
                                            </p>
                                            <p>
                                                <Link to="mailto:support@eroedgetechnologies.com">
                                                    <i className="fal fa-envelope-open">
                                                    </i> support@eroedge<br />technologies.com
                                                </Link>
                                            </p>
                                            <p>
                                                <i className="fal fa-phone">
                                                </i> +91 9698143213
                                            </p>

                                        </div>
                                        <img src="assets/img/logo/ero_edge_logo.webp" alt="ero_edge_logo" className="custom_logo_footer_design" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="widget nav-widget pl-lg-4">
                                    <h4 className="widget-title">Quick Link</h4>
                                    <ul>
                                        <li><Link to="/Software">Software Development</Link></li>
                                        <li><Link to="/Mobile_app">Mobile Application Development</Link></li>
                                        <li><Link to="/AI">Artificial Intelligence(AI)</Link></li>
                                        <li><Link to="/ML">Machine Learning (ML)</Link></li>
                                        <li><Link to="/Digital">Digital Marketing</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-6 col-sm-6">
                                <div className="widget nav-widget">
                                    <h4 className="widget-title">Support</h4>
                                    <ul>
                                        <li><Link to="/About">About Us</Link></li>
                                        <li><Link to="/Privacy">Privacy & Policy</Link></li>
                                        <li><Link to="/Careers">Need a Career?</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="widget">
                                    <h4 className="widget-title">Follow</h4>
                                    <ul className="social-links">
                                        <li><Link to="https://www.linkedin.com/company/ero-edge-technologies-private-limited"><i className="fab fa-linkedin"></i></Link></li>
                                        <li><Link to="https://www.facebook.com/p/Ero-Edge-Technologies-Private-Limited-61556438934930/"><i className="fab fa-facebook-square"></i></Link></li>
                                        <li><Link to="https://www.instagram.com/ero_edge_technologies/"><i className="fab fa-instagram"></i></Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="copyright-box">
                        <p>@ 2024 Ero Edge Technologies Pvt Ltd</p>
                    </div>
                </div>
            </footer>
            {/* <!--====== Template Footer End ======--> */}

        </div>

    );
}

export default Web;