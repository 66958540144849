import React from "react";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import Preloaders from "../components/Preloaders";
import Testimonial from "../components/Testimonial";



function Home() {

    const [isMenuOpen, setMenuOpen] = useState(false);
    const [isSubMenuOpen, setSubMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!isMenuOpen);
        // Close submenu when main menu is toggled
        setSubMenuOpen(false);
    };

    const toggleSubMenu = () => {
        setSubMenuOpen(!isSubMenuOpen);
    };
    const closeMenu = () => {
        setMenuOpen(false);
    };

    /*landing page opens at the top of the page*/
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    /* Title page and meta description content has been load from here for their rspective page */
    useEffect(() => {
        document.title = "Ero Edge | Your Trusted Software Development Partner";
        const metaDescriptionTag = document.querySelector('meta[name="description"]');
        if (metaDescriptionTag) {
            metaDescriptionTag.setAttribute('content', 'Ero Edge Technologies provides top-notch software development services, helping businesses build custom solutions that drive growth.');
        }
    }, []);

    return (

        <div className="restrict_content">
            {/* <!--====== Start Preloader ======--> */}
            <Preloaders />
            {/* <!--====== End Preloader ======-->  */}


            {/* <!--====== Start Header ======--> */}

            <header className="template-header absolute-header navbar-left sticky-header">
                <div className="container">
                    <div className="header-inner">
                        <div className="header-left">
                            <div className="site-logo">
                                <Link to="/Home">
                                    <img src="assets/img/logo/ero_edge_logo.webp" alt="ero_edge_logo" className="custom_logo_design" />
                                </Link>
                            </div>
                            <nav className="nav-menu d-none d-xl-block">
                                <ul className="primary-menu">
                                    <li>
                                        <Link to="/Home" className="active">Home</Link>
                                    </li>
                                    <li>
                                        <Link to="#">Services</Link>
                                        <ul className="submenu">
                                            <li><Link to="/Software">Software Development</Link></li>
                                            <li><Link to="/Web">Web Application Development</Link></li>
                                            <li><Link to="/Mobile">Mobile Application Development</Link></li>
                                            <li><Link to="/AI">Artificial Intelligence (AI)</Link></li>
                                            <li><Link to="/ML">Machine Learning (ML)</Link></li>
                                            <li><Link to="/Digital">Digital Marketing</Link></li>
                                        </ul>
                                    </li>
                                    <li>
                                        <Link to="/Careers">Careers</Link>
                                    </li>
                                    <li>
                                        <Link to="/About">About Us</Link>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                        <div className="header-right">
                            <ul className="header-extra">
                                <li className="header-btns d-none d-md-block">
                                    <Link to="/Contact" className="template-btn">
                                        Contact Us
                                        <i className="far fa-long-arrow-right"></i>
                                    </Link>
                                </li>

                                <li className="d-xl-none">
                                    {/* Mobile-menu */}
                                    <div className="#" onClick={toggleMenu}>
                                        <div className="navbar-toggler hamburger_align">
                                            <span className="hamburger_span_align"></span>
                                            <span></span>
                                            <span></span>
                                        </div>
                                    </div>
                                    {isMenuOpen && (
                                        <div className=" mobile-slide-panel-1 slide-panel-1 ">
                                            <div className="panel-inner">
                                                <div className="custom-close-button" onClick={closeMenu}>
                                                    <i className="fal fa-times"></i>
                                                </div>
                                                <div className="custom-panel-logo">
                                                    <img src="assets/img/logo/ero_edge_logo.webp" alt="ero_edge_logo" className="custom_logo_menu_design" />
                                                </div>
                                                <div>
                                                    <nav className="mobile-menu">
                                                        <ul>
                                                            <li>
                                                                <Link to="/Home" className="active">Home</Link>
                                                            </li>
                                                            <li>
                                                                <Link to="#" onClick={toggleSubMenu}>Services</Link>
                                                                {isSubMenuOpen && (
                                                                    <ul className="custom-submenu">
                                                                        <li><Link to="/Software">Software Development</Link></li>
                                                                        <li><Link to="/Web">Web Application Development</Link></li>
                                                                        <li><Link to="/Mobile">Mobile Application Development</Link></li>
                                                                        <li><Link to="/AI">Artificial Intelligence(AI)</Link></li>
                                                                        <li><Link to="/ML">Machine Learning (ML)</Link></li>
                                                                        <li><Link to="/Digital">Digital Marketing</Link></li>
                                                                    </ul>
                                                                )}
                                                            </li>
                                                            <li>
                                                                <Link to="/Careers">Careers</Link>
                                                            </li>
                                                            <li>
                                                                <Link to="/About">About us</Link>
                                                            </li>
                                                        </ul>
                                                        <div>
                                                            <Link to="/Contact" className="template-btn hamburger_contact_btn_align">
                                                                Contact Us
                                                                <i className="far fa-long-arrow-right"></i>
                                                            </Link>
                                                        </div>
                                                    </nav>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </header>

            {/* <!--====== End Header ======-->

    <!--====== Start Hero Area ======--> */}

            <section className="hero-area-one">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-10">
                            <div className="hero-content">
                                <h2 className="wow fadeInDown" data-wow-delay="0.3s">
                                    Empowering digital success through expert software development and innovative solutions.
                                </h2>
                                <br></br>
                                <Link to="/Contact" className="template-btn wow fadeInUp" data-wow-delay="0.4s">
                                    Contact Us <i className="far fa-long-arrow-right"></i>
                                </Link>
                                <p className="wow fadeInUp" data-wow-delay="0.5s">
                                    Welcome to Ero Edge Technologies Private Limited, where innovation meets excellence in software development for a digital future.
                                </p>
                            </div>
                        </div>

                        <div className="col-lg-6 order-lg-last">
                            <div className="custom-row d-lg-block wow fadeInRight" data-wow-delay="0.3s" style={{ paddingTop: "20px", marginBottom: "-50px" }}>
                                <img src="assets/img/illustrations/hero-illustration-one.png" alt="Illustration" />
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            {/* <!--====== End Hero Area ======--> */}

            {/* <!--====== About Area Start ======--> */}

            <section className="about-section section-gap bg-color-secondary section-animate-icons">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-lg-6 col-md-10">
                            <div className="about-img content-mb-md-50 wow fadeInUp">
                                <img src="assets/img/illustrations/about-illustration.png" alt="about-illustration" className="animate-float-bob-y" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-8">
                            <div className="about-text color-white-70 content-x-spacing">
                                <p>
                                    Where innovation thrives and digital excellence unfolds. Transform your ideas into reality with our cutting-edge software solutions.
                                </p>
                                <div className="simple-icon-box icon-left mt-30 mb-40">
                                    <div className="icon color-tertiary">
                                        <i className="flaticon-stats"></i>
                                    </div>
                                    <div className="content">
                                        <p>
                                            Embark on a digital journey where creativity meets functionality. Unleash the potential of your ideas with our leading-edge software solutions.
                                        </p>
                                    </div>
                                </div>

                                <Link to="/About" className="template-btn btn-hover-white-bg">Learn More Us <i className="far fa-long-arrow-right"></i></Link>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <!-- Animate Icons --> */}

                <div className="animate-icons">
                    <img src="assets/img/shape/line-rectangle.png" alt="Icon" className="line-rectangle" />
                    <img src="assets/img/shape/dots-circle.png" alt="Icon" className="dots-circle animate-zoom-fade" />
                    <img src="assets/img/shape/dots-square.png" alt="Icon" className="dots-square" />
                    <img src="assets/img/shape/wave-line-white.png" alt="Icon" className="wave-line" />
                </div>
            </section>

            {/* <!--====== About Area End ======-->

    <!--====== Service Section Start ======--> */}
            <section className="service-section section-gap bg-color-primary-7 bg-cover-center overflow-hidden" style={{ backgroundImage: "url(assets/img/hero/service-bg-2.jpg)" }}>
                <div className="container">
                    <div className="section-heading text-center mb-30">
                        <h2 className="title">Services We Offer</h2>
                        <span className="tagline">Leading Digital Consulting</span>
                    </div>
                    <div className="row align-items-center justify-content-center">
                        <div className="col-lg-12">
                            <div className="justify-content-center custom_row">
                                <div className="col-lg-4 col-md-6 col-sm-8 col-11 wow fadeInUp custom_card_align" data-wow-delay="0.1s">
                                    <div className="fancy-iconic-box mt-30">
                                        <Link to="/Software">
                                            <div className="icon">
                                                <img src="assets/img/icon/service1.svg" alt="service_1" className="custom_img_icon" />
                                            </div>
                                            <h4 className="title">Software Development</h4>
                                            <p>Crafting code that transforms concepts into seamless software solutions.</p>
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-8 col-11 wow fadeInUp custom_card_align" data-wow-delay="0.2s">
                                    <div className="fancy-iconic-box mt-30">
                                        <Link to="/Web">
                                            <div className="icon">
                                                <img src="assets/img/icon/service2.svg" alt="service_2" className="custom_img_icon" />
                                            </div>
                                            <h4 className="title">Web Application Development</h4>
                                            <p>Empowering businesses with dynamic and intuitive web applications.</p>
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-8 col-11 wow fadeInUp custom_card_align" data-wow-delay="0.3s">
                                    <div className="fancy-iconic-box mt-30">
                                        <Link to="/Mobile">
                                            <div className="icon">
                                                <img src="assets/img/icon/service3.svg" alt="service_3" className="custom_img_icon" />
                                            </div>
                                            <h4 className="title">Mobile Application Development</h4>
                                            <p>Innovative mobile app development for a connected and mobile-first world.</p>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="service-boxes justify-content-center custom_row">
                                <div className="col-lg-4 col-md-6 col-sm-8 col-11 wow fadeInUp custom_card_align" data-wow-delay="0.4s">
                                    <div className="fancy-iconic-box mt-30">
                                        <Link to="/AI">
                                            <div className="icon">
                                                <img src="assets/img/icon/service4.svg" alt="service_4" className="custom_img_icon" />
                                            </div>
                                            <h4 className="title">Artificial Intelligence (AI)</h4>
                                            <p>Harnessing the power of Artificial Intelligence for intelligent solutions.</p>
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-8 col-11 wow fadeInUp custom_card_align" data-wow-delay="0.5s">
                                    <div className="fancy-iconic-box mt-30">
                                        <Link to="/ML">
                                            <div className="icon">
                                                <img src="assets/img/icon/service5.svg" alt="service_5" className="custom_img_icon" />
                                            </div>
                                            <h4 className="title">Machine Learning (ML)</h4>
                                            <p>Pioneering solutions with cutting-edge Machine Learning algorithms.</p>
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-8 col-11 wow fadeInUp custom_card_align" data-wow-delay="0.6s">
                                    <div className="fancy-iconic-box mt-30">
                                        <Link to="/Digital">
                                            <div className="icon">
                                                <img src="assets/img/icon/service6.svg" alt="service_6" className="custom_img_icon" />
                                            </div>
                                            <h4 className="title">Digital marketing</h4>
                                            <p>Driving digital success through strategic and impactful marketing.</p>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <!--====== Service Section End ======-->

    <!--====== Case & Work Process Section Start ======--> */}

            <section className="case-section bg-color-off-white section-gap overflow-hidden">
                <div className="container">
                    <div className="section-heading text-center mb-30">
                        <h2 className="title">Working Process</h2>
                        <span className="tagline">Innovate, Elevate, Code Great!</span>
                    </div>
                    <div className="fancy-step-boxes">
                        <div className="single-step-box wow fadeInUp" data-wow-delay="0.3s">
                            <div className="step-box-inner">
                                <div className="content">
                                    <div className="icon">
                                        <i className="flaticon-support"></i>
                                    </div>
                                    <h5 className="title">Welcome To EroEdge</h5>
                                </div>
                                <span className="step-count">01</span>
                            </div>
                        </div>
                        <div className="single-step-box wow fadeInUp" data-wow-delay="0.4s">
                            <div className="step-box-inner">
                                <div className="content">
                                    <div className="icon">
                                        <i className="flaticon-target"></i>
                                    </div>
                                    <h5 className="title">Choose Your Services</h5>
                                </div>
                                <span className="step-count">02</span>
                            </div>
                        </div>
                        <div className="single-step-box wow fadeInUp" data-wow-delay="0.5s">
                            <div className="step-box-inner">
                                <div className="content">
                                    <div className="icon">
                                        <i className="flaticon-diagnostic"></i>
                                    </div>
                                    <h5 className="title">Your Project  Analysis</h5>
                                </div>
                                <span className="step-count">03</span>
                            </div>
                        </div>
                        <div className="single-step-box wow fadeInUp" data-wow-delay="0.6s">
                            <div className="step-box-inner">
                                <div className="content">
                                    <div className="icon">
                                        <i className="flaticon-badge"></i>
                                    </div>
                                    <h5 className="title">Get Final Results</h5>
                                </div>
                                <span className="step-count">04</span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            {/* <!--====== Case & Work Process Section End ======-->

    <!--====== Testimonial Section Start ======--> */}

            <Testimonial />

            {/* <!--====== Testimonial Section End ======--> */}

            {/* <!--====== Call To Action Start ======--> */}

            <section className="call-to-action bg-color-secondary section-animate-icons">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-lg-5 order-lg-last">
                            <div className="cta-illustrations text-center text-lg-right content-mb-md-50">
                                <img src="assets/img/illustrations/cta-illustrations.png" alt="cta-illustrations" />
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-10">
                            <div className="cta-content">
                                <h2 className="title">Explore our customized development service solutions for professional results. Are you prepared to enhance your online presence ? </h2>
                                <Link to="/About" className="template-btn">Get in Touch ! <i className="far fa-long-arrow-right"></i></Link>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <!-- Animate Icons --> */}

                <div className="animate-icons">
                    <img src="assets/img/shape/dots-circle.png" alt="Icon" className="dots-circle animate-float-bob-x" />
                </div>
                <div className="cta-shape">
                    <img src="assets/img/shape/cta-shape.png" alt="Shape" />
                </div>
            </section>

            {/* <!--====== Call To Action End ======--> */}

            {/* <!--====== Template Footer Start ======--> */}

            <footer className="template-footer">
                <div className="container">
                    <div className="footer-widgets-area">
                        <div className="row">
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <div className="widget contact-widget">
                                    <h4 className="widget-title">Contact Us</h4>
                                    <div className="contact-content">
                                        <div className="icon">
                                            <p>
                                                <i className="fal fa-map-marker-alt">
                                                </i> 483, 3rdFloor, Block.No:18<br /> Laakini Towers, Vasuki Street,<br />Erode.
                                            </p>
                                            <p>
                                                <Link to="mailto:support@eroedgetechnologies.com">
                                                    <i className="fal fa-envelope-open">
                                                    </i> support@eroedge<br />technologies.com
                                                </Link>
                                            </p>
                                            <p>
                                                <i className="fal fa-phone">
                                                </i> +91 9698143213
                                            </p>

                                        </div>
                                        <img src="assets/img/logo/ero_edge_logo.webp" alt="ero_edge_logo" className="custom_logo_footer_design" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="widget nav-widget pl-lg-4">
                                    <h4 className="widget-title">Quick Link</h4>
                                    <ul>
                                        <li><Link to="/Software">Software Development</Link></li>
                                        <li><Link to="/Web">Web Application Development</Link></li>
                                        <li><Link to="/Mobile">Mobile Application Development</Link></li>
                                        <li><Link to="/AI">Artificial Intelligence(AI)</Link></li>
                                        <li><Link to="/ML">Machine Learning (ML)</Link></li>
                                        <li><Link to="/Digital">Digital Marketing</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-6 col-sm-6">
                                <div className="widget nav-widget">
                                    <h4 className="widget-title">Support</h4>
                                    <ul>
                                        <li><Link to="/About">About Us</Link></li>
                                        <li><Link to="/Privacy">Privacy & Policy</Link></li>
                                        <li><Link to="/Careers">Need a Career?</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="widget">
                                    <h4 className="widget-title">Follow</h4>
                                    <ul className="social-links">
                                        <li><Link to="https://www.linkedin.com/company/ero-edge-technologies-private-limited"><i className="fab fa-linkedin"></i></Link></li>
                                        <li><Link to="https://www.facebook.com/p/Ero-Edge-Technologies-Private-Limited-61556438934930/"><i className="fab fa-facebook-square"></i></Link></li>
                                        <li><Link to="https://www.instagram.com/ero_edge_technologies/"><i className="fab fa-instagram"></i></Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="copyright-box">
                        <p>@ 2024 Ero Edge Technologies Pvt Ltd</p>
                    </div>
                </div>
            </footer>

            {/* <!--====== Template Footer End ======--> */}

        </div>

    );
}

export default Home;

