import React from "react";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import Preloaders from "../components/Preloaders";


function Careers() {

    const [isMenuOpen, setMenuOpen] = useState(false);
    const [isSubMenuOpen, setSubMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!isMenuOpen);
        // Close submenu when main menu is toggled
        setSubMenuOpen(false);
    };

    const toggleSubMenu = () => {
        setSubMenuOpen(!isSubMenuOpen);
    };
    const closeMenu = () => {
        setMenuOpen(false);
    };

    /*landing page opens at the top of the page*/
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    /* Title page and meta description content has been load from here for their rspective page */
    useEffect(() => {
        document.title = "Join the Innovation: Build Your Career at Ero Edge Technologies";
        const metaDescriptionTag = document.querySelector('meta[name="description"]');
        if (metaDescriptionTag) {
            metaDescriptionTag.setAttribute('content', 'Are you passionate about software development? Join Ero Edge Technologies and contribute to cutting-edge projects with a vibrant team! Explore open positions now.');
        }
    }, []);

    return (

        <div className="restrict_content">
            {/* <!--====== Start Preloader ======--> */}
            <Preloaders />
            {/* <!--====== End Preloader ======-->  */}

            {/*<!--====== Start Header ======--> */}
            <header className="template-header absolute-header navbar-left header-gradient-bg sticky-header">
                <div className="container">
                    <div className="header-inner">
                        <div className="header-left">
                            <div className="site-logo">
                                <Link to="/Home">
                                    <img src="assets/img/logo/ero_edge_logo.webp" alt="ero_edge_logo" className="custom_logo_design" />
                                </Link>
                            </div>
                            <nav className="nav-menu d-none d-xl-block">
                                <ul className="primary-menu">
                                    <li>
                                        <Link to="/Home">Home</Link>
                                    </li>
                                    <li>
                                        <Link to="#">Services</Link>
                                        <ul className="submenu">
                                            <li><Link to="/Software">Software Development</Link></li>
                                            <li><Link to="/Web">Web Application Development</Link></li>
                                            <li><Link to="/Mobile">Mobile Application Development</Link></li>
                                            <li><Link to="/AI">Artificial Intelligence (AI)</Link></li>
                                            <li><Link to="/ML">Machine Learning (ML)</Link></li>
                                            <li><Link to="/Digital">Digital Marketing</Link></li>
                                        </ul>
                                    </li>
                                    <li>
                                        <Link to="/Careers" className="active">Careers</Link>
                                    </li>
                                    <li>
                                        <Link to="/About">About Us</Link>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                        <div className="header-right">
                            <ul className="header-extra">
                                <li className="header-btns d-none d-sm-block">
                                    <Link to="/Contact" className="template-btn">
                                        Contact Us
                                        <i className="far fa-long-arrow-right"></i>
                                    </Link>
                                </li>

                                <li className="d-xl-none">
                                    {/* Mobile-menu */}
                                    <div className="#" onClick={toggleMenu}>
                                        <div className="navbar-toggler hamburger_align">
                                            <span className="hamburger_span_align"></span>
                                            <span></span>
                                            <span></span>
                                        </div>
                                    </div>
                                    {isMenuOpen && (
                                        <div className=" mobile-slide-panel-1 slide-panel-1 ">
                                            <div className="panel-inner">
                                                <div className="custom-close-button" onClick={closeMenu}>
                                                    <i className="fal fa-times"></i>
                                                </div>
                                                <div className="custom-panel-logo">
                                                    <img src="assets/img/logo/ero_edge_logo.webp" alt="ero_edge_logo" className="custom_logo_menu_design" />
                                                </div>
                                                <div>
                                                    <nav className="mobile-menu">
                                                        <ul>
                                                            <li>
                                                                <Link to="/Home">Home</Link>
                                                            </li>
                                                            <li>
                                                                <Link to="#" onClick={toggleSubMenu}>Services</Link>
                                                                {isSubMenuOpen && (
                                                                    <ul className="custom-submenu">
                                                                        <li><Link to="/Software">Software Development</Link></li>
                                                                        <li><Link to="/Web">Web Application Development</Link></li>
                                                                        <li><Link to="/Mobile">Mobile Application Development</Link></li>
                                                                        <li><Link to="/AI">Artificial Intelligence(AI)</Link></li>
                                                                        <li><Link to="/ML">Machine Learning (ML)</Link></li>
                                                                        <li><Link to="/Digital">Digital Marketing</Link></li>
                                                                    </ul>
                                                                )}
                                                            </li>
                                                            <li>
                                                                <Link to="/Careers" className="active">Careers</Link>
                                                            </li>
                                                            <li>
                                                                <Link to="/About">About us</Link>
                                                            </li>
                                                        </ul>
                                                        <div>
                                                            <Link to="/Contact" className="template-btn hamburger_contact_btn_align">
                                                                Contact Us
                                                                <i className="far fa-long-arrow-right"></i>
                                                            </Link>
                                                        </div>
                                                    </nav>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </header>
            {/* <!--====== End Header ======-->
        
            <!--====== Page Title Area Start ======--> */}
            <section className="page-title-area">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <h2 className="page-title">Careers</h2>
                        </div>
                        <div className="col-md-6">
                            <ul className="breadcrumb-nav">
                                <li><Link to="/Home">Home</Link></li>
                                <li>Careers</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!--====== Page Title Area End ======-->
        
            <!--====== Careers Info Start ======--> */}
            <section className="contact-info-section section-gap bg-cover-center careers_bg">
                <div className="container">
                    <div className="row justify-content-lg-between justify-content-center">
                        <div className="col-xl-5 col-lg-6 col-md-10">
                            <div className="section-heading mb-30" style={{ marginBottom: '-20px' }}>
                                <h2 className="title">Learn and Grow As A Ero Edge!</h2>
                                <br></br>
                                <h4 style={{ color: '#2667ff' }}>Unleash your full potential at Ero Edge Technologies in the company
                                    of an impeccably talented team, and with the constant support of our experienced leadership.</h4>
                            </div>
                            {/* <!--====== Open position Section Start ======--> */}
                        </div>
                        <div className="col-lg-6 col-md-9" style={{ marginBottom: '-30px' }}>
                            <div className="contact-img text-lg-right text-center content-mt-md-50">
                                <img src="assets/img/service_images/careers.png" alt="careers" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!--====== Careers Info End ======--> */}

            {/* <!--====== Openings Position Start ======--> */}
            <div className="bg-cover-center careers_bg">
                <div className="section-heading mb-30 container" >
                    <h2 className="title"> Explore Open Positions ! </h2>
                </div>
                <div className="custom_grid_container justify-content-center">
                    <div className="col-lg-5 col-md-10 careers_card_bg_align">
                        <ul className="custom-icon-bordered-list">
                            <li className="wow fadeInUp" data-wow-delay="0.3s">
                                <div className="content" >
                                    <h5 className="title careers_card_heading_div_bg_align">Software Developer (Flutter Mobile Application)</h5>
                                    <div className="careers_card_heading_div_bg_align">
                                        <i className="fal fa-map-marker-alt"></i> Erode
                                        <i className="fal fa-briefcase careers_card_i_tag_align"></i> 1 Year Experience
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <p className="careers_card_p_tag_line"></p>
                        <Link to="mailto:hr@eroedgetechnologies.com" className="template-btn btn-hover-white-bg careers_card_btn_align ">
                            Apply Now
                            <i className="far fa-long-arrow-right"></i>
                        </Link>
                    </div>
                    <div className="col-lg-5 col-md-10 careers_card_bg_align">
                        <ul className="custom-icon-bordered-list">
                            <li className="wow fadeInUp" data-wow-delay="0.3s">
                                <div className="content" >
                                    <h5 className="title careers_card_heading_div_bg_align">Software Developer (Laravel)</h5>
                                    <div className="careers_card_heading_div_bg_align">
                                        <i className="fal fa-map-marker-alt"></i> Erode
                                        <i className="fal fa-briefcase careers_card_i_tag_align"></i> 1 to 3 Year Experience
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <p className="careers_card_p_tag_line"></p>
                        <Link to="mailto:hr@eroedgetechnologies.com" className="template-btn btn-hover-white-bg careers_card_btn_align ">
                            Apply Now
                            <i className="far fa-long-arrow-right"></i>
                        </Link>
                    </div>
                </div>

            </div>
            {/* <!--====== Openings Position End ======-->

             {/* <!--====== Core Value Section Start ======--> */}
            <div className="bg-cover-center careers_bg" style={{ marginTop: "-30px" }}>
                <div className="section-heading mb-30 container" style={{ paddingTop: "50px" }}>
                    <h2 className="title">Our Core Values</h2>
                </div>
                <section className="counter-section counter-boxed bg-cover-center careers_bg">
                    <div className="container bg-color-primary section-wave-bg">
                        <div className="counter-items row justify-content-lg-between justify-content-center">
                            <div className="col-xl-4 col-lg-3 col-sm-5">
                                <div className="counter-item counter-white mt-40">
                                    <h2 className="careers_core_value_heading">Transparency</h2>
                                    <br />
                                    <h6>Foster customer loyality and confidence</h6>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-3 col-sm-5">
                                <div className="counter-item counter-white mt-40">
                                    <h2 className="careers_core_value_heading">Togetherness</h2>
                                    <br />
                                    <h6>Binds the team and give us strength</h6>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-3 col-sm-5">
                                <div className="counter-item counter-white mt-40">
                                    <h2 className="careers_core_value_heading">Trust</h2>
                                    <br />
                                    <h6>Foundation to nurture meaningful relationships</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            {/* <!--====== Core Value Section End ======--> */}

            {/*<!--====== Call To Action Start ======--> */}
            <section className="call-to-action bg-color-secondary section-animate-icons">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-lg-5 order-lg-last">
                            <div className="cta-illustrations text-center text-lg-right content-mb-md-50">
                                <img src="assets/img/illustrations/cta-illustrations.png" alt="cta-illustrations" />
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-10">
                            <div className="cta-content">
                                <h2 className="title">Explore our customized development service solutions for professional results. Are you prepared to enhance your online presence ? </h2>
                                <Link to="/About" className="template-btn">Get in Touch ! <i className="far fa-long-arrow-right"></i></Link>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <!-- Animate Icons --> */}
                <div className="animate-icons">
                    <img src="assets/img/shape/dots-circle.png" alt="Icon" className="dots-circle" />
                </div>
                <div className="cta-shape">
                    <img src="assets/img/shape/cta-shape.png" alt="Shape" />
                </div>
            </section>
            {/* <!--====== Call To Action End ======-->
        
            <!--====== Template Footer Start ======--> */}
            <footer className="template-footer">
                <div className="container">
                    <div className="footer-widgets-area">
                        <div className="row">
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <div className="widget contact-widget">
                                    <h4 className="widget-title">Contact Us</h4>
                                    <div className="contact-content">
                                        <div className="icon">
                                            <p>
                                                <i className="fal fa-map-marker-alt">
                                                </i> 483, 3rdFloor, Block.No:18<br /> Laakini Towers, Vasuki Street,<br />Erode.
                                            </p>
                                            <p>
                                                <Link to="mailto:support@eroedgetechnologies.com">
                                                    <i className="fal fa-envelope-open">
                                                    </i> support@eroedge<br />technologies.com
                                                </Link>
                                            </p>
                                            <p>
                                                <i className="fal fa-phone">
                                                </i> +91 9698143213
                                            </p>

                                        </div>
                                        <img src="assets/img/logo/ero_edge_logo.webp" alt="ero_edge_logo" className="custom_logo_footer_design" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="widget nav-widget pl-lg-4">
                                    <h4 className="widget-title">Quick Link</h4>
                                    <ul>
                                        <li><Link to="/Software">Software Development</Link></li>
                                        <li><Link to="/Web">Web Application Development</Link></li>
                                        <li><Link to="/Mobile">Mobile Application Development</Link></li>
                                        <li><Link to="/AI">Artificial Intelligence (AI)</Link></li>
                                        <li><Link to="/ML">Machine Learning (ML)</Link></li>
                                        <li><Link to="/Digital">Digital Marketing</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-6 col-sm-6">
                                <div className="widget nav-widget">
                                    <h4 className="widget-title">Support</h4>
                                    <ul>
                                        <li><Link to="/About">About Us</Link></li>
                                        <li><Link to="/Privacy">Privacy & Policy</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="widget">
                                    <h4 className="widget-title">Follow</h4>
                                    <ul className="social-links">
                                        <li><Link to="https://www.linkedin.com/company/ero-edge-technologies-private-limited"><i className="fab fa-linkedin"></i></Link></li>
                                        <li><Link to="https://www.facebook.com/p/Ero-Edge-Technologies-Private-Limited-61556438934930/"><i className="fab fa-facebook-square"></i></Link></li>
                                        <li><Link to="https://www.instagram.com/ero_edge_technologies/"><i className="fab fa-instagram"></i></Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="copyright-box">
                        <p>@ 2024 Ero Edge Technologies Pvt Ltd</p>
                    </div>
                </div>
            </footer>
            {/* <!--====== Template Footer End ======--> */}

        </div>




    );
}

export default Careers;