import React from "react";
import { useState } from "react";


function ContactForm() {

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        subject: '',
        website: '',
        message: ''
    });

    const handleFocus = (e) => {
        e.preventDefault(); // Prevent page from scrolling
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const body = `Name: ${formData.name}\nEmail: ${formData.email}\nPhone: ${formData.phone}\nSubject: ${formData.subject}\nWebsite: ${formData.website}\nMessage: ${formData.message}`;
        const mailtoLink = `mailto:support@eroedgetechnologies.com?subject=${encodeURIComponent(formData.subject)}&body=${encodeURIComponent(body)}`;
        window.location.href = mailtoLink;

        setFormData({
            name: '',
            email: '',
            phone: '',
            subject: '',
            website: '',
            message: ''
        });
    };

    return (
        <div>
            {/* <!--====== Contact Info End ======--> */}

            {/* <!--====== Contact Maps Start ======--> */}
            <section className="contact-map-area overflow-hidden">
                {/* <script async> */}
                    <div className="contact-map">
                        <iframe src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=+(Ero%20Edge%20Technologies%20Private%20Limited)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed" loading="lazy" title="officeLocation"></iframe>
                    </div>
                {/* </script> */}
            </section>
            {/* <!--====== Contact Maps End ======--> */}

            {/* <!--====== Contact Form Start ======--> */}
            <section className="contact-from-area section-gap">
                <div className="container">
                    <div className="contact-from">
                        <div className="section-heading text-center mb-60">
                            <h2 className="title">Send Us Message</h2>
                            <span className="tagline">Turning Ideas into Digital Reality.</span>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-md-4 col-sm-6">
                                    <div className="input-field">
                                        <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} onFocus={handleFocus} placeholder="Your Full Name" required />
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <div className="input-field">
                                        <input type="tel" id="phone" name="phone" value={formData.phone} onChange={handleChange} onFocus={handleFocus} placeholder="Phone Number" required />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="input-field">
                                        <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} onFocus={handleFocus} placeholder="Email Address" required />
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="input-field">
                                        <input type="url" id="website" name="website" value={formData.website} onChange={handleChange} onFocus={handleFocus} placeholder="Website" />
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="input-field">
                                        <input type="text" id="subject" name="subject" value={formData.subject} onChange={handleChange} onFocus={handleFocus} placeholder="Subject" required />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="input-field">
                                        <textarea id="message" name="message" value={formData.message} onChange={handleChange} onFocus={handleFocus} placeholder="Message" required></textarea>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <button type="submit" className="template-btn w-100">Send Message</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
            {/* <!--====== Contact Form End ======--> */}
        </div>
    );
}

export default ContactForm;