import React from "react";
import { Link } from "react-router-dom"
import { useEffect, useState } from "react";
import Preloaders from "../components/Preloaders";
import 'react-accessible-accordion/dist/fancy-example.css';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';


function About() {

    const [isMenuOpen, setMenuOpen] = useState(false);
    const [isSubMenuOpen, setSubMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!isMenuOpen);
        // Close submenu when main menu is toggled
        setSubMenuOpen(false);
    };

    const toggleSubMenu = () => {
        setSubMenuOpen(!isSubMenuOpen);
    };
    const closeMenu = () => {
        setMenuOpen(false);
    };

    /*landing page opens at the top of the page*/
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    /* Title page and meta description content has been load from here for their rspective page */
    useEffect(() => {
        document.title = "Ero Edge Technologies: Unlocking Potential, Empowering Businesses";
        const metaDescriptionTag = document.querySelector('meta[name="description"]');
        if (metaDescriptionTag) {
            metaDescriptionTag.setAttribute('content', 'Uncover the core principles and driving forces behind Ero Edge. Understand what makes us different and how we empower businesses to thrive.');
        }
    }, []);


    return (


        <div className="restrict_content">
            {/* <!--====== Start Preloader ======--> */}
            <Preloaders />
            {/* <!--====== End Preloader ======-->  */}
            {/* <!--====== Start Header ======--> */}
            <header className="template-header absolute-header navbar-left sticky-header">
                <div className="container">
                    <div className="header-inner">
                        <div className="header-left">
                            <div className="site-logo">
                                <Link to="/Home">
                                    <img src="assets/img/logo/ero_edge_logo.webp" alt="ero_edge_logo" className="custom_logo_design" />
                                </Link>
                            </div>
                            <nav className="nav-menu d-none d-xl-block">
                                <ul className="primary-menu">
                                    <li>
                                        <Link to="/Home">Home</Link>
                                    </li>
                                    <li>
                                        <Link to="#">Services</Link>
                                        <ul className="submenu">
                                            <li><Link to="/Software">Software Development</Link></li>
                                            <li><Link to="/Web">Web Application Development</Link></li>
                                            <li><Link to="/Mobile">Mobile Application Development</Link></li>
                                            <li><Link to="/AI">Artificial Intelligence(AI)</Link></li>
                                            <li><Link to="/ML">Machine Learning (ML)</Link></li>
                                            <li><Link to="/Digital">Digital Marketing</Link></li>
                                        </ul>
                                    </li>
                                    <li>
                                        <Link to="/Careers">Careers</Link>
                                    </li>
                                    <li>
                                        <Link to="/About" className="active">About Us</Link>
                                    </li>

                                </ul>
                            </nav>
                        </div>

                        <div className="header-right">
                            <ul className="header-extra">
                                <li className="header-btns d-none d-sm-block">
                                    <Link to="/Contact" className="template-btn">
                                        Contact Us
                                        <i className="far fa-long-arrow-right"></i>
                                    </Link>
                                </li>
                                <li className="d-xl-none">
                                    {/* Mobile-menu */}
                                    <div className="#" onClick={toggleMenu}>
                                        <div className="navbar-toggler hamburger_align">
                                            <span className="hamburger_span_align"></span>
                                            <span></span>
                                            <span></span>
                                        </div>
                                    </div>
                                    {isMenuOpen && (
                                        <div className=" mobile-slide-panel-1 slide-panel-1 ">
                                            <div className="panel-inner">
                                                <div className="custom-close-button" onClick={closeMenu}>
                                                    <i className="fal fa-times"></i>
                                                </div>
                                                <div className="custom-panel-logo">
                                                    <img src="assets/img/logo/ero_edge_logo.webp" alt="ero_edge_logo" className="custom_logo_menu_design" />
                                                </div>
                                                <div>
                                                    <nav className="mobile-menu">
                                                        <ul>
                                                            <li>
                                                                <Link to="/Home">Home</Link>
                                                            </li>
                                                            <li>
                                                                <Link to="#" onClick={toggleSubMenu}>Services</Link>
                                                                {isSubMenuOpen && (
                                                                    <ul className="custom-submenu">
                                                                        <li><Link to="/Software">Software Development</Link></li>
                                                                        <li><Link to="/Web">Web Application Development</Link></li>
                                                                        <li><Link to="/Mobile">Mobile Application Development</Link></li>
                                                                        <li><Link to="/AI">Artificial Intelligence(AI)</Link></li>
                                                                        <li><Link to="/ML">Machine Learning (ML)</Link></li>
                                                                        <li><Link to="/Digital">Digital Marketing</Link></li>
                                                                    </ul>
                                                                )}
                                                            </li>
                                                            <li>
                                                                <Link to="/Careers">Careers</Link>
                                                            </li>
                                                            <li>
                                                                <Link to="/About" className="active">About us</Link>
                                                            </li>
                                                        </ul>
                                                        <div>
                                                            <Link to="/Contact" className="template-btn hamburger_contact_btn_align">
                                                                Contact Us
                                                                <i className="far fa-long-arrow-right"></i>
                                                            </Link>
                                                        </div>
                                                    </nav>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </header>
            {/* <!--====== End Header ======-->

    <!--====== Page Title Area Start ======--> */}
            <section className="page-title-area">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <h2 className="page-title">About us</h2>
                        </div>
                        <div className="col-md-6">
                            <ul className="breadcrumb-nav">
                                <li>
                                    <Link to="/Home">Home</Link>
                                </li>
                                <li>
                                    About Us
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!--====== Page Title Area End ======-->

    <!--====== Feature Section Start ======--> */}
            <section className="feature-section section-gap">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-lg-6 col-md-10">
                            <div className="feature-text-block content-r-spacing">
                                <div className="section-heading mb-50">
                                    <h2 className="title">Welcome to Ero Edge Technologies – Shaping Digital Excellence</h2>
                                    <span className="tagline">At Ero Edge Technologies, we are more than a software development company; we are architects of innovation and pioneers of cutting-edge solutions. Since our inception, we have been dedicated to pushing the boundaries of what's possible in the digital landscape.</span>
                                </div>
                                <div className="feature-lists">
                                    <div className="simple-icon-box icon-left wow fadeInUp mb-30">
                                        <div className="icon">
                                            <img src="assets/img/icon/our_story.svg" alt="our_story" className="custom_img_icon_2" />
                                        </div>
                                        <div className="content">
                                            <h4 className="title">Our Story</h4>
                                            <p>Founded on the belief that technology should be a catalyst for positive change, Ero Edge Technologies began its journey with a vision to revolutionize the way businesses leverage digital solutions. Over the years, we have evolved, grown, and adapted, but our commitment to innovation and client success remains unwavering.</p>
                                        </div>
                                    </div>
                                    <div className="simple-icon-box icon-left wow fadeInUp mb-30">
                                        <div className="icon">
                                            <img src="assets/img/icon/our_mission.svg" alt="our_mission" className="custom_img_icon_2" />
                                        </div>
                                        <div className="content">
                                            <h4 className="title">Our Mission</h4>
                                            <p>Our mission is to empower businesses with transformative digital solutions that drive growth, efficiency, and a competitive edge. We strive to be at the forefront of technological advancements, delivering solutions that not only meet current needs but anticipate future challenges.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-10">
                            <div className="feature-img text-lg-right text-center content-mt-md-50" style={{ marginBottom: "-20px" }}>
                                <img src="assets/img/service_images/fresh_idea.jpg" alt="fresh_ideas" className="animate-float-bob-x" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <!--====== Feature Section End ======--> */}

            <div className="service-with-team">
                {/* <!--====== Service Section Start ======--> */}
                <section className="service-section section-gap">
                    <div className="container">
                        <div className="section-heading heading-white text-center mb-30">
                            <h2 className="title">What Sets Us Apart</h2>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-lg-4 col-sm-6 wow fadeInUp custom_card_align">
                                <div className="iconic-box icon-left bg-white mt-30">
                                    <div className="icon">
                                        <img src="assets/img/icon/business_idea.svg" alt="business_idea" />
                                    </div>
                                    <div className="content">
                                        <h5 className="title">
                                            Innovation at the Core
                                        </h5>
                                        <p>We embrace creativity and innovation, weaving them into the fabric of our solutions to provide a unique and impactful digital experience.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 wow fadeInUp custom_card_align">
                                <div className="iconic-box icon-left bg-white mt-30">
                                    <div className="icon">
                                        <img src="assets/img/icon/employee_skills.svg" alt="employee_skills" />
                                    </div>
                                    <div className="content">
                                        <h5 className="title">
                                            <Link to="/Service_details">Expertise Across Domains</Link>
                                        </h5>
                                        <p>From software and web application development to AI, ML, and digital marketing, our diverse expertise allows us to offer comprehensive solutions tailored to your specific needs.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 wow fadeInUp custom_card_align">
                                <div className="iconic-box icon-left bg-white mt-30">
                                    <div className="icon">
                                        <img src="assets/img/icon/client_centric.svg" alt="client_centric" />
                                    </div>
                                    <div className="content">
                                        <h5 className="title">
                                            Client-Centric Approach
                                        </h5>
                                        <p>Your success is our priority. We believe in building lasting partnerships, working collaboratively to understand your goals and deliver solutions that exceed expectations.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            {/* <!--====== Service Section End ======--> */}

            {/* <!--====== Faq Section Start ======--> */}

            <section className="faq-section section-gap">
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-lg-6 order-lg-last">
                            <div className="faq-image text-center text-lg-right content-mb-md-50">
                                <img src="assets/img/service_images/faq.jpg" className="animate-float-bob-y" alt="faq" style={{ marginTop: "-100px" }} />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-10">
                            <div className="faq-content content-r-spacing">
                                <div className="section-heading mb-30">
                                    <h2 className="title">Our Team</h2>
                                    <span className="tagline">Behind every successful project is a team of dedicated and passionate individuals. Our diverse team of developers, designers, and strategists bring a wealth of experience and expertise to the table, ensuring that every project is a success story.</span>
                                </div>
                                <div className="section-heading mb-30">
                                    <h2 className="title">Our commitment</h2>
                                </div>
                                <Accordion>
                                    <div className="accordion-active">

                                        <AccordionItem className="accordion-item">
                                            <AccordionItemHeading>
                                                <AccordionItemButton style={{ backgroundColor: "rgb(38, 103, 255, 0.07)" }}>
                                                    <h5 className="about_faq_heading">
                                                        Quality Assurance
                                                    </h5>
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p className="accordion-content faq_paragraph">
                                                    Rigorous testing and quality assurance processes ensure that every product we deliver meets the highest standards.
                                                </p>
                                            </AccordionItemPanel>
                                        </AccordionItem>

                                        <AccordionItem className="accordion-item">
                                            <AccordionItemHeading>
                                                <AccordionItemButton style={{ backgroundColor: "rgb(38, 103, 255, 0.07)" }}>
                                                    <h5 className="about_faq_heading">
                                                        Timely Delivery
                                                    </h5>
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p className="accordion-content faq_paragraph">
                                                    We understand the value of time in the fast-paced digital world. Our commitment to deadlines ensures that your projects are delivered on schedule.
                                                </p>
                                            </AccordionItemPanel>
                                        </AccordionItem>

                                        <AccordionItem className="accordion-item">
                                            <AccordionItemHeading >
                                                <AccordionItemButton style={{ backgroundColor: "rgb(38, 103, 255, 0.07)" }}>
                                                    <h5 className="about_faq_heading">
                                                        Continuous Improvement
                                                    </h5>
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p className="accordion-content faq_paragraph_last">
                                                    Technology evolves, and so do we. We are committed to staying at the forefront of industry trends, bringing the latest innovations to your projects.
                                                </p>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                    </div>
                                </Accordion>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <!--====== Call To Action Start ======--> */}
            <section className="call-to-action bg-color-secondary section-animate-icons">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-lg-5 order-lg-last">
                            <div className="cta-illustrations text-center text-lg-right content-mb-md-50">
                                <img src="assets/img/illustrations/cta-illustrations.png" alt="cta-illustrations" />
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-10">
                            <div className="cta-content">
                                <h2 className="title"> Ready to Transform Your Ideas into Reality ?</h2>
                                <p className="subtitle">Contact us today to discuss your software development needs and embark on a journey to digital excellence.</p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <!-- Animate Icons --> */}
                <div className="animate-icons">
                    <img src="assets/img/shape/dots-circle.png" alt="Icon" className="dots-circle" />
                </div>
                <div className="cta-shape">
                    <img src="assets/img/shape/cta-shape.png" alt="Shape" />
                </div>
            </section>
            {/* <!--====== Call To Action End ======-->

    <!--====== Template Footer Start ======--> */}
            <footer className="template-footer">
                <div className="container">
                    <div className="footer-widgets-area">
                        <div className="row">
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <div className="widget contact-widget">
                                    <h4 className="widget-title">Contact Us</h4>
                                    <div className="contact-content">
                                        <div className="icon">
                                            <p>
                                                <i className="fal fa-map-marker-alt">
                                                </i> 483, 3rdFloor, Block.No:18<br /> Laakini Towers, Vasuki Street,<br />Erode.
                                            </p>
                                            <p>
                                                <Link to="mailto:support@eroedgetechnologies.com">
                                                    <i className="fal fa-envelope-open">
                                                    </i> support@eroedge<br />technologies.com
                                                </Link>
                                            </p>
                                            <p>
                                                <i className="fal fa-phone">
                                                </i> +91 9698143213
                                            </p>

                                        </div>
                                        <img src="assets/img/logo/ero_edge_logo.webp" alt="ero_edge_logo" className="custom_logo_footer_design" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="widget nav-widget pl-lg-4">
                                    <h4 className="widget-title">Quick Link</h4>
                                    <ul>
                                        <li><Link to="/Software">Software Development</Link></li>
                                        <li><Link to="/Web">Web Application Development</Link></li>
                                        <li><Link to="/Mobile">Mobile Application Development</Link></li>
                                        <li><Link to="/AI">Artificial Intelligence(AI)</Link></li>
                                        <li><Link to="/ML">Machine Learning (ML)</Link></li>
                                        <li><Link to="/Digital">Digital Marketing</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-6 col-sm-6">
                                <div className="widget nav-widget">
                                    <h4 className="widget-title">Support</h4>
                                    <ul>
                                        <li><Link to="/Privacy">Privacy & Policy</Link></li>
                                        <li><Link to="/Careers">Need a Career?</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="widget">
                                    <h4 className="widget-title">Follow</h4>
                                    <ul className="social-links">
                                        <li><Link to="https://www.linkedin.com/company/ero-edge-technologies-private-limited"><i className="fab fa-linkedin"></i></Link></li>
                                        <li><Link to="https://www.facebook.com/p/Ero-Edge-Technologies-Private-Limited-61556438934930/"><i className="fab fa-facebook-square"></i></Link></li>
                                        <li><Link to="https://www.instagram.com/ero_edge_technologies/"><i className="fab fa-instagram"></i></Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="copyright-box">
                        <p>@ 2024 Ero Edge Technologies Pvt Ltd</p>
                    </div>
                </div>
            </footer>
            {/* <!--====== Template Footer End ======--> */}

        </div>

    );
}

export default About;