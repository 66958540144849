import { BrowserRouter, Routes, Route } from 'react-router-dom';

// Import your page components
import About from './pages/About';
import AI from './pages/AI';
import Careers from './pages/Careers';
import Contact from './pages/Contact';
import Digital from './pages/Digital';
import Home from './pages/Home';
import ML from './pages/ML';
import Mobile from './pages/Mobile';
import Software from './pages/Software';
import Web from './pages/Web';
import Privacy from './pages/Privacy';

function App() {

  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route index element={<Home />} />
          <Route path="/About" element={<About />} />
          <Route path="/AI" element={<AI />} />
          <Route path="/Careers" element={<Careers />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/Digital" element={<Digital />} />
          <Route path="/Home" element={<Home />} />
          <Route path="/ML" element={<ML />} />
          <Route path="/Mobile" element={<Mobile />} />
          <Route path="/Privacy" element={<Privacy />} />
          <Route path="/Software" element={<Software />} />
          <Route path="/Web" element={<Web />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;