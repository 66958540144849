import React from "react";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import Preloaders from "../components/Preloaders";

function Digital() {

    const [isMenuOpen, setMenuOpen] = useState(false);
    const [isSubMenuOpen, setSubMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!isMenuOpen);
        // Close submenu when main menu is toggled
        setSubMenuOpen(false);
    };

    const toggleSubMenu = () => {
        setSubMenuOpen(!isSubMenuOpen);
    };
    const closeMenu = () => {
        setMenuOpen(false);
    };

    /*landing page opens at the top of the page*/
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    /* Title page and meta description content has been load from here for their rspective page */
    useEffect(() => {
        document.title = "Reach, Engage, Convert: Supercharge Your Brand with our Expertise";
        const metaDescriptionTag = document.querySelector('meta[name="description"]');
        if (metaDescriptionTag) {
            metaDescriptionTag.setAttribute('content', 'Tired of stagnant results? Our data-driven digital marketing strategies get you noticed, engage your audience, and drive measurable conversions.');
        }
    }, []);

    return (

        <div className="restrict_content">
            {/* <!--====== Start Preloader ======--> */}
            <Preloaders />
            {/* <!--====== End Preloader ======-->  */}

            {/*<!--====== Start Header ======--> */}
            <header className="template-header absolute-header navbar-left sticky-header">
                <div className="container">
                    <div className="header-inner">
                        <div className="header-left">
                            <div className="site-logo">
                                <Link to="/Home">
                                    <img src="assets/img/logo/ero_edge_logo.webp" alt="ero_edge_logo" className="custom_logo_design" />
                                </Link>
                            </div>
                            <nav className="nav-menu d-none d-xl-block">
                                <ul className="primary-menu">
                                    <li>
                                        <Link to="/Home">Home</Link>
                                    </li>
                                    <li>
                                        <Link to="#" className="active">Services</Link>
                                        <ul className="submenu">
                                            <li><Link to="/Software">Software Development</Link></li>
                                            <li><Link to="/Web">Web Application Development</Link></li>
                                            <li><Link to="/Mobile">Mobile Application Development</Link></li>
                                            <li><Link to="/AI">Artificial Intelligence (AI)</Link></li>
                                            <li><Link to="/ML">Machine Learning (ML)</Link></li>
                                            <li><Link to="/Digital">Digital Marketing</Link></li>
                                        </ul>
                                    </li>
                                    <li>
                                        <Link to="/Careers">Careers</Link>
                                    </li>
                                    <li>
                                        <Link to="/About">About Us</Link>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                        <div className="header-right">
                            <ul className="header-extra">
                                <li className="header-btns d-none d-sm-block">
                                    <Link to="/Contact" className="template-btn">
                                        Contact Us
                                        <i className="far fa-long-arrow-right"></i>
                                    </Link>
                                </li>
                                <li className="d-xl-none">
                                    {/* Mobile-menu */}
                                    <div className="#" onClick={toggleMenu}>
                                        <div className="navbar-toggler hamburger_align">
                                            <span className="hamburger_span_align"></span>
                                            <span></span>
                                            <span></span>
                                        </div>
                                    </div>
                                    {isMenuOpen && (
                                        <div className=" mobile-slide-panel-1 slide-panel-1 ">
                                            <div className="panel-inner">
                                                <div className="custom-close-button" onClick={closeMenu}>
                                                    <i className="fal fa-times"></i>
                                                </div>
                                                <div className="custom-panel-logo">
                                                    <img src="assets/img/logo/ero_edge_logo.webp" alt="ero_edge_logo" className="custom_logo_menu_design" />
                                                </div>
                                                <div>
                                                    <nav className="mobile-menu">
                                                        <ul>
                                                            <li>
                                                                <Link to="/Home">Home</Link>
                                                            </li>
                                                            <li>
                                                                <Link to="#" onClick={toggleSubMenu}>Services</Link>
                                                                {isSubMenuOpen && (
                                                                    <ul className="custom-submenu">
                                                                        <li><Link to="/Software">Software Development</Link></li>
                                                                        <li><Link to="/Web">Web Application Development</Link></li>
                                                                        <li><Link to="/Mobile">Mobile Application Development</Link></li>
                                                                        <li><Link to="/AI">Artificial Intelligence(AI)</Link></li>
                                                                        <li><Link to="/ML">Machine Learning (ML)</Link></li>
                                                                        <li><Link to="/Digital" className="active">Digital Marketing</Link></li>
                                                                    </ul>
                                                                )}
                                                            </li>
                                                            <li>
                                                                <Link to="/Careers">Careers</Link>
                                                            </li>
                                                            <li>
                                                                <Link to="/About" className="active">About us</Link>
                                                            </li>
                                                        </ul>
                                                        <div>
                                                            <Link to="/Contact" className="template-btn hamburger_contact_btn_align">
                                                                Contact Us
                                                                <i className="far fa-long-arrow-right"></i>
                                                            </Link>
                                                        </div>
                                                    </nav>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </header>
            {/* <!--====== End Header ======-->

    <!--====== Page Title Area Start ======--> */}
            <section className="page-title-area">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <h2 className="page-title">Digital Marketing</h2>
                        </div>
                        <div className="col-md-6">
                            <ul className="breadcrumb-nav">
                                <li><Link to="/Home">Home</Link></li>
                                <li>Digital Marketing</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!--====== Page Title Area End ======-->

    <!--====== About Area Start ======--> */}
            <section className="about-section section-gap overflow-hidden">
                <div className="custom-container-fluid">
                    <div className="row align-items-center justify-content-xl-start justify-content-center">
                        <div className="col-xl-4 col-lg-8 col-sm-10">
                            <div className="about-contant content-mb-lg-80">
                                <div className="section-heading mb-20">
                                    <h2 className="title">Improve Your Business By Digital Solutions</h2>
                                    <span className="tagline">Best Digital Agency Solutions</span>
                                </div>
                                <p>
                                    Welcome to Ero Edge Technologies, your gateway to results-driven Digital Marketing solutions. Transform your online presence, connect with your audience, and drive measurable success with our comprehensive suite of digital marketing services.
                                </p>
                                <ul className="custom_circle_list mb-40 mt-30 wow fadeInUp">
                                    <div className="icon">
                                        <li><img src="assets/img/icon/approach.svg" alt="approach" className="custom_img_icon_3" />Our Approach </li>
                                        <p>At Ero Edge, we take a holistic approach to digital marketing, integrating strategies that resonate with your brand and goals. From enhancing your online visibility to maximizing engagement, we tailor our solutions to ensure you stand out in the digital landscape</p>
                                        <br></br>
                                        <li><img src="assets/img/icon/technology.svg" alt="technology" className="custom_img_icon_3" />Technological Expertise </li>
                                        <p>Our digital marketing experts are well-versed in the latest tools and trends, ensuring that your campaigns are not only effective but also ahead of the curve.</p>
                                        <br></br>
                                        <li><img src="assets/img/icon/industrial.svg" alt="industrial" className="custom_img_icon_3" />Industries We Serve </li>
                                        <p>Our digital marketing strategies have proven success across various industries, including healthcare, e-commerce, technology, and more. We understand the unique challenges each sector faces and tailor our services accordingly.</p>
                                    </div>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-10">
                            <div className="feature-img text-lg-left text-center content-mb-md-50" style={{ paddingTop: "20px", marginBottom: "-20px" }} >
                                <img src="assets/img/service_images/digital_market.svg" alt="digital_market" className="animate-float-bob-y service_img_align" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!--====== About Area End ======-->

    <!--====== Service Section Start ======--> */}
            <section className="service-section section-gap bg-color-primary-7 bg-cover-center" style={{ backgroundImage: "url(assets/img/hero/hero-bg-three.jpg)" }}>
                <div className="container">
                    <div className="section-heading mb-30 text-center">
                        <h2 className="title">Services Offered</h2>
                    </div>
                    <div className="row service-boxes justify-content-center">
                        <div className="col-lg-4 col-md-6 col-sm-8 col-11 wow fadeInUp custom_card_align" data-wow-delay="0.1s">
                            <div className="fancy-iconic-box mt-30 color-4">
                                <div className="icon">
                                    <i className="flaticon-seo-2"></i>
                                </div>
                                <h4 className="title">Search Engine Optimization (SEO)</h4>
                                <p>Boost your website's visibility and climb the search engine ranks for increased organic traffic.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-8 col-11 wow fadeInUp custom_card_align custom_card_align" data-wow-delay="0.2s">
                            <div className="fancy-iconic-box mt-30 color-3">
                                <div className="icon">
                                    <i className="flaticon-startup"></i>
                                </div>
                                <h4 className="title">Social Media Marketing</h4>
                                <p>Connect with your audience on platforms that matter most, building a vibrant online community.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-8 col-11 wow fadeInUp custom_card_align" data-wow-delay="0.3s">
                            <div className="fancy-iconic-box mt-30 color-2">
                                <div className="icon">
                                    <i className="flaticon-click"></i>
                                </div>
                                <h4 className="title">Pay-Per-Click (PPC) Advertising</h4>
                                <p>Drive targeted traffic and achieve instant visibility through strategically crafted PPC campaigns.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!--====== Service Section End ======--> */}


            {/* <!--====== Service Section-2 Start ======--> */}

            <section className="service-section section-gap">
                <div className="container">
                    <div className="section-heading mb-20">
                        <h2 className="title">key Features</h2>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="service-boxes justify-content-center custom_row">
                                <div className="col-lg-5 col-md-6 col-sm-8 col-11 wow fadeInUp custom_card_align" data-wow-delay="0.1s">
                                    <div className="fancy-iconic-box mt-30 color-1">

                                        <h4 className="title">Data-Driven Strategies</h4>
                                        <p>Utilize analytics to refine your digital marketing approach for optimal results.</p>
                                    </div>
                                </div>
                                <div className="col-lg-5 col-md-6 col-sm-8 col-11 wow fadeInUp custom_card_align" data-wow-delay="0.2s">
                                    <div className="fancy-iconic-box mt-30 color-4">

                                        <h4 className="title">Content Marketing</h4>
                                        <p>Engage your audience with compelling content that establishes your brand as an industry authority.</p>
                                    </div>
                                </div>
                                <div className="col-lg-5 col-md-6 col-sm-8 col-11 wow fadeInUp custom_card_align" data-wow-delay="0.3s">
                                    <div className="fancy-iconic-box mt-30 color-3">

                                        <h4 className="title">Conversion Optimization</h4>
                                        <p>Maximize the value of your traffic by optimizing for conversions and lead generation.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-8" style={{ marginTop: '50px', marginBottom: '-60px' }}>
                            <div className="about-contant content-mb-lg-80">
                                <div className="section-heading mb-20">
                                    <h2 className="title">Why Choose Ero Edge for Digital Marketing</h2>
                                </div>
                                <ul className="circle-list mb-40 mt-30 wow fadeInUp">
                                    <li>Customized Solutions</li>
                                    <p>Tailored strategies that align with your brand, goals, and target audience.</p>
                                    <br></br>
                                    <li>Measurable Results</li>
                                    <p>Track and measure the success of your campaigns with transparent and insightful reporting.</p>
                                    <br></br>
                                    <li>Adaptability</li>
                                    <p>Stay ahead of the digital curve with a marketing team that adapts to evolving trends and technologies.</p>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <!--====== Service Section-2 End ======--> */}

            {/* <!--====== Call To Action Start ======--> */}
            <section className="call-to-action bg-color-secondary section-animate-icons">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-lg-5 order-lg-last">
                            <div className="cta-illustrations text-center text-lg-right content-mb-md-50">
                                <img src="assets/img/illustrations/cta-illustrations.png" alt="cta-illustrations" />
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-10">
                            <div className="cta-content">
                                <h2 className="title">Ready to Amplify Your Online Presence ?  </h2>
                                <p className="subtitle">Contact us today to discuss your digital marketing needs and discover how our expertise can elevate your brand, increase visibility, and drive measurable success in the digital realm.</p>
                                <Link to="/About" className="template-btn">Get in Touch ! <i className="far fa-long-arrow-right"></i></Link>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <!-- Animate Icons --> */}
                <div className="animate-icons">
                    <img src="assets/img/shape/dots-circle.png" alt="Icon" className="dots-circle" />
                </div>
                <div className="cta-shape">
                    <img src="assets/img/shape/cta-shape.png" alt="Shape" />
                </div>
            </section>
            {/* <!--====== Call To Action End ======-->

    <!--====== Template Footer Start ======--> */}
            <footer className="template-footer">
                <div className="container">
                    <div className="footer-widgets-area">
                        <div className="row">
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <div className="widget contact-widget">
                                    <h4 className="widget-title">Contact Us</h4>
                                    <div className="contact-content">
                                        <div className="icon">
                                            <p>
                                                <i className="fal fa-map-marker-alt">
                                                </i> 483, 3rdFloor, Block.No:18<br /> Laakini Towers, Vasuki Street,<br />Erode.
                                            </p>
                                            <p>
                                                <Link to="mailto:support@eroedgetechnologies.com">
                                                    <i className="fal fa-envelope-open">
                                                    </i> support@eroedge<br />technologies.com
                                                </Link>
                                            </p>
                                            <p>
                                                <i className="fal fa-phone">
                                                </i> +91 9698143213
                                            </p>

                                        </div>
                                        <img src="assets/img/logo/ero_edge_logo.webp" alt="ero_edge_logo" className="custom_logo_footer_design" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="widget nav-widget pl-lg-4">
                                    <h4 className="widget-title">Quick Link</h4>
                                    <ul>
                                        <li><Link to="/Software">Software Development</Link></li>
                                        <li><Link to="/Web">Web Application Development</Link></li>
                                        <li><Link to="/Mobile">Mobile Application Development</Link></li>
                                        <li><Link to="/AI">Artificial Intelligence(AI)</Link></li>
                                        <li><Link to="/ML">Machine Learning (ML)</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-6 col-sm-6">
                                <div className="widget nav-widget">
                                    <h4 className="widget-title">Support</h4>
                                    <ul>
                                        <li><Link to="/About">About Us</Link></li>
                                        <li><Link to="/Privacy">Privacy & Policy</Link></li>
                                        <li><Link to="/Careers">Need a Career?</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="widget">
                                    <h4 className="widget-title">Follow</h4>
                                    <ul className="social-links">
                                        <li><Link to="https://www.linkedin.com/company/ero-edge-technologies-private-limited"><i className="fab fa-linkedin"></i></Link></li>
                                        <li><Link to="https://www.facebook.com/p/Ero-Edge-Technologies-Private-Limited-61556438934930/"><i className="fab fa-facebook-square"></i></Link></li>
                                        <li><Link to="https://www.instagram.com/ero_edge_technologies/"><i className="fab fa-instagram"></i></Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="copyright-box">
                        <p>@ 2024 Ero Edge Technologies Pvt Ltd</p>
                    </div>
                </div>
            </footer>

            {/* <!--====== Template Footer End ======--> */}

        </div>

    );
}

export default Digital;