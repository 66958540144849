import React from "react";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import Preloaders from "../components/Preloaders";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import ContactForm from "../components/contactForm";


function Contact() {

    const [isMenuOpen, setMenuOpen] = useState(false);
    const [isSubMenuOpen, setSubMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!isMenuOpen);
        // Close submenu when main menu is toggled
        setSubMenuOpen(false);
    };

    const toggleSubMenu = () => {
        setSubMenuOpen(!isSubMenuOpen);
    };
    const closeMenu = () => {
        setMenuOpen(false);
    };

    /*landing page opens at the top of the page*/
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    /* Title page and meta description content has been load from here for their rspective page */
    useEffect(() => {
        document.title = "Partner with the Best: We're Here to Help You Succeed";
        const metaDescriptionTag = document.querySelector('meta[name="description"]');
        if (metaDescriptionTag) {
            metaDescriptionTag.setAttribute('content', "Don't wait, join the growing list of successful businesses powered by Ero Edge. Talk to our team and start your journey to software success!");
        }
    }, []);

    return (
        <div className="restrict_content">
            {/* <!--====== Start Preloader ======--> */}
            <Preloaders />
            {/* <!--====== End Preloader ======-->  */}

            {/* <!--====== Start Header ======--> */}
            <header className="template-header absolute-header navbar-left header-gradient-bg sticky-header">
                <div className="container">
                    <div className="header-inner">
                        <div className="header-left">
                            <div className="site-logo">
                                <Link to="/Home">
                                    <img src="assets/img/logo/ero_edge_logo.webp" alt="ero_edge_logo" className="custom_logo_design" />
                                </Link>
                            </div>
                            <nav className="nav-menu d-none d-xl-block">
                                <ul className="primary-menu">
                                    <li>
                                        <Link to="/Home">Home</Link>
                                    </li>
                                    <li>
                                        <Link to="#">Services</Link>
                                        <ul className="submenu">
                                            <li><Link to="/Software">Software Development</Link></li>
                                            <li><Link to="/Web">Web Application Development</Link></li>
                                            <li><Link to="/Mobile">Mobile Application Development</Link></li>
                                            <li><Link to="/AI">Artificial Intelligence (AI)</Link></li>
                                            <li><Link to="/ML">Machine Learning (ML)</Link></li>
                                            <li><Link to="/Digital">Digital Marketing</Link></li>
                                        </ul>
                                    </li>
                                    <li>
                                        <Link to="/Careers">Careers</Link>
                                    </li>
                                    <li>
                                        <Link to="/About">About Us</Link>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                        <div className="header-right">
                            <ul className="header-extra">
                                <li className="header-btns d-none d-sm-block">
                                    <Link to="/Contact" className="template-btn">
                                        Contact Us
                                        <i className="far fa-long-arrow-right"></i>
                                    </Link>
                                </li>
                                <li className="d-xl-none">
                                    {/* Mobile-menu */}
                                    <div className="#" onClick={toggleMenu}>
                                        <div className="navbar-toggler hamburger_align">
                                            <span className="hamburger_span_align"></span>
                                            <span></span>
                                            <span></span>
                                        </div>
                                    </div>
                                    {isMenuOpen && (
                                        <div className=" mobile-slide-panel-1 slide-panel-1 ">
                                            <div className="panel-inner">
                                                <div className="custom-close-button" onClick={closeMenu}>
                                                    <i className="fal fa-times"></i>
                                                </div>
                                                <div className="custom-panel-logo">
                                                    <img src="assets/img/logo/ero_edge_logo.webp" alt="ero_edge_logo" className="custom_logo_menu_design" />
                                                </div>
                                                <div>
                                                    <nav className="mobile-menu">
                                                        <ul>
                                                            <li>
                                                                <Link to="/Home">Home</Link>
                                                            </li>
                                                            <li>
                                                                <Link to="#" onClick={toggleSubMenu}>Services</Link>
                                                                {isSubMenuOpen && (
                                                                    <ul className="custom-submenu">
                                                                        <li><Link to="/Software">Software Development</Link></li>
                                                                        <li><Link to="/Web">Web Application Development</Link></li>
                                                                        <li><Link to="/Mobile">Mobile Application Development</Link></li>
                                                                        <li><Link to="/AI">Artificial Intelligence(AI)</Link></li>
                                                                        <li><Link to="/ML">Machine Learning (ML)</Link></li>
                                                                        <li><Link to="/Digital">Digital Marketing</Link></li>
                                                                    </ul>
                                                                )}
                                                            </li>
                                                            <li>
                                                                <Link to="/Careers">Careers</Link>
                                                            </li>
                                                            <li>
                                                                <Link to="/About">About us</Link>
                                                            </li>
                                                        </ul>
                                                    </nav>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </header>
            {/* <!--====== End Header ======--> */}

            {/* <!--====== Page Title Area Start ======--> */}
            <section className="page-title-area">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <h2 className="page-title">Contact Us</h2>
                        </div>
                        <div className="col-md-6">
                            <ul className="breadcrumb-nav">
                                <li><Link to="/Home">Home</Link></li>
                                <li>Contact Us</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!--====== Page Title Area End ======--> */}

            {/* <!--====== Contact Info Start ======--> */}
            <section className="contact-info-section section-gap">
                <div className="container">
                    <div className="row justify-content-lg-between justify-content-center">
                        <div className="col-xl-5 col-lg-6 col-md-10">
                            <div className="section-heading mb-30">
                                <h2 className="title">Ready To Get Popular Marketing Service</h2>
                                <span className="tagline">Crafting Tomorrow's Software Today.</span>
                            </div>
                            <div className="contact-info-box wow fadeInUp">
                                <div className="single-contact-info">
                                    <div className="icon">
                                        <i className="fal fa-map-marker-alt"></i>
                                    </div>
                                    <div className="content">
                                        <h4 className="info-title">Address</h4>
                                        <p>483, 3rdFloor, Block.No:18<br />Laakini Towers, Vasuki Street, Erode</p>
                                    </div>
                                </div>
                                <div className="single-contact-info">
                                    <div className="icon">
                                        <i className="fal fa-envelope-open"></i>
                                    </div>
                                    <div className="content">
                                        <h4 className="info-title">Email Us</h4>
                                        <p>support@eroedge<br />technologies.com</p>
                                    </div>
                                </div>
                                <div className="single-contact-info">
                                    <div className="icon">
                                        <i className="fal fa-phone"></i>
                                    </div>
                                    <div className="content">
                                        <h4 className="info-title">Phone</h4>
                                        <p>+91 9698143213</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-9">
                            <div className="contact-img text-lg-right text-center content-mt-md-50">
                                <img src="assets/img/service_images/contacts.jpg" alt="contacts" className="animate-float-bob-y" style={{ marginBottom: "-50px" }} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!--====== Contact Info End ======--> */}

            <ContactForm />

            {/* <!--====== Contact Form End ======--> */}

            {/* <!--====== Faq Section Start ======--> */}
            <section className="faq-section">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-10">
                            <div className="section-heading text-center mb-50">
                                <h2 className="title">Our Asked Questions</h2>
                                <span className="tagline">Empowering Dreams through Code Excellence.</span>
                            </div>
                            <Accordion>
                                <div className="accordion-active">

                                    <AccordionItem className="accordion-item">
                                        <AccordionItemHeading>
                                            <AccordionItemButton style={{ backgroundColor: "rgb(38, 103, 255, 0.07)" }}>
                                                <h5 className="contact_faq_heading">
                                                    How do I get started with Ero Edge Technologies ?
                                                </h5>
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p className="accordion-content faq_paragraph">
                                                Getting started is simple. Reach out to us through our contact form, and one of our dedicated team members will connect with you promptly to discuss your requirements and outline the next steps.
                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>

                                    <AccordionItem className="accordion-item">
                                        <AccordionItemHeading>
                                            <AccordionItemButton style={{ backgroundColor: "rgb(38, 103, 255, 0.07)" }}>
                                                <h5 className="contact_faq_heading">
                                                    What industries does Ero Edge Technologies serve ?
                                                </h5>
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p className="accordion-content faq_paragraph">
                                                Ero Edge Technologies caters to a diverse range of industries, including healthcare, finance, e-commerce, and more. Our expertise allows us to tailor solutions to meet the unique challenges of each sector.
                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>

                                    <AccordionItem className="accordion-item">
                                        <AccordionItemHeading >
                                            <AccordionItemButton style={{ backgroundColor: "rgb(38, 103, 255, 0.07)" }}>
                                                <h5 className="contact_faq_heading">
                                                    What sets Ero Edge apart from other software development companies ?
                                                </h5>
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p className="accordion-content faq_paragraph">
                                                At Ero Edge, we stand out through our commitment to innovation, a client-centric approach, and expertise across various domains. Our focus on quality, timely delivery, and continuous improvement sets us apart in the competitive landscape.
                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>

                                    <AccordionItem className="accordion-item">
                                        <AccordionItemHeading >
                                            <AccordionItemButton style={{ backgroundColor: "rgb(38, 103, 255, 0.07)" }}>
                                                <h5 className="contact_faq_heading">
                                                    How do you ensure the security of our data and information ?
                                                </h5>
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p className="accordion-content faq_paragraph">
                                                We prioritize the security of your data. Ero Edge Technologies implements robust security measures at every stage of the development process, ensuring the confidentiality and integrity of your information.
                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>

                                    <AccordionItem className="accordion-item">
                                        <AccordionItemHeading >
                                            <AccordionItemButton style={{ backgroundColor: "rgb(38, 103, 255, 0.07)" }}>
                                                <h5 className="contact_faq_heading">
                                                    What is the typical timeline for completing a software development project ?
                                                </h5>
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p className="accordion-content faq_paragraph_last">
                                                Project timelines can vary based on the complexity and scope of the project. During our initial discussions, we'll provide you with a detailed timeline based on your requirements. Our commitment to timely delivery ensures your project progresses efficiently.
                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                </div>
                            </Accordion>
                        </div>
                    </div>
                </div>
            </section>
            <br></br>
            {/* <!--====== Faq Section End ======--> */}

            {/* <!--====== Call To Action Start ======--> */}
            <section className="call-to-action bg-color-secondary section-animate-icons">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-lg-5 order-lg-last">
                            <div className="cta-illustrations text-center text-lg-right content-mb-md-50">
                                <img src="assets/img/illustrations/cta-illustrations.png" alt="cta-illustrations" />
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-10">
                            <div className="cta-content">
                                <h2 className="title"> Ready to Transform Your Ideas into Reality ?</h2>
                                <p className="subtitle">Contact us today to discuss your software development needs and embark on a journey to digital excellence.</p>
                                <Link to="/About" className="template-btn">Get in Touch ! <i className="far fa-long-arrow-right"></i></Link>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <!-- Animate Icons --> */}
                <div className="animate-icons">
                    <img src="assets/img/shape/dots-circle.png" alt="Icon" className="dots-circle" />
                </div>
                <div className="cta-shape">
                    <img src="assets/img/shape/cta-shape.png" alt="Shape" />
                </div>
            </section>
            {/* <!--====== Call To Action End ======--> */}

            {/* <!--====== Template Footer Start ======--> */}
            <footer className="template-footer">
                <div className="container">
                    <div className="footer-widgets-area">
                        <div className="row">
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <div className="widget contact-widget">
                                    <h4 className="widget-title">Contact Us</h4>
                                    <div className="contact-content">
                                        <div className="icon">
                                            <p>
                                                <i className="fal fa-map-marker-alt">
                                                </i> 483, 3rdFloor, Block.No:18<br /> Laakini Towers, Vasuki Street,<br />Erode.
                                            </p>
                                            <p>
                                                <Link to="mailto:support@eroedgetechnologies.com">
                                                    <i className="fal fa-envelope-open">
                                                    </i> support@eroedge<br />technologies.com
                                                </Link>
                                            </p>
                                            <p>
                                                <i className="fal fa-phone">
                                                </i> +91 9698143213
                                            </p>

                                        </div>
                                        <img src="assets/img/logo/ero_edge_logo.webp" alt="ero_edge_logo" className="custom_logo_footer_design" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="widget nav-widget pl-lg-4">
                                    <h4 className="widget-title">Quick Link</h4>
                                    <ul>
                                        <li><Link to="/Software">Software Development</Link></li>
                                        <li><Link to="/Web">Web Application Development</Link></li>
                                        <li><Link to="/Mobile">Mobile Application Development</Link></li>
                                        <li><Link to="/AI">Artificial Intelligence (AI)</Link></li>
                                        <li><Link to="/ML">Machine Learning (ML)</Link></li>
                                        <li><Link to="/Digital">Digital Marketing</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-6 col-sm-6">
                                <div className="widget nav-widget">
                                    <h4 className="widget-title">Support</h4>
                                    <ul>
                                        <li><Link to="/About">About Us</Link></li>
                                        <li><Link to="/Privacy">Privacy & Policy</Link></li>
                                        <li><Link to="/Careers">Need a Career?</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="widget">
                                    <h4 className="widget-title">Follow</h4>
                                    <ul className="social-links">
                                        <li><Link to="https://www.linkedin.com/company/ero-edge-technologies-private-limited"><i className="fab fa-linkedin"></i></Link></li>
                                        <li><Link to="https://www.facebook.com/p/Ero-Edge-Technologies-Private-Limited-61556438934930/"><i className="fab fa-facebook-square"></i></Link></li>
                                        <li><Link to="https://www.instagram.com/ero_edge_technologies/"><i className="fab fa-instagram"></i></Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="copyright-box">
                        <p>@ 2024 Ero Edge Technologies Pvt Ltd</p>
                    </div>
                </div>
            </footer>
            {/* <!--====== Template Footer End ======--> */}

        </div>
    );
}

export default Contact;